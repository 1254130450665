import React from "react";
import { Button, Row, Col } from "react-bootstrap";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
}

const PaginationControls: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  goToNextPage,
  goToPreviousPage,
}) => {
  return (
    <Row>
      <Col className="d-flex justify-content-between my-4">
        <Button
          className="pagination-controls btn-dark"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          className="pagination-controls btn-dark"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Col>
    </Row>
  );
};

export default PaginationControls;

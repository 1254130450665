import React from "react";
import { Card, Button } from "react-bootstrap";
import { CreatedOrdersInfo } from "../../models/ResponseModels";

interface OrderCardProps {
  details: CreatedOrdersInfo;
  handleAddOrder: (seatName: string) => void;
  handleCompleteOrder: (orderId: number, seatName: string) => void;
  shouldShowDate?: boolean;
  cardBackgroundColor?: string;
}

const CreatedOrderCard: React.FC<OrderCardProps> = ({
  details,
  handleAddOrder,
  handleCompleteOrder,
  shouldShowDate = true,
  cardBackgroundColor = "#f9f4f0",
}) => {
  console.log("res ", details);
  return (
    <Card
      className="mb-4 mt-3"
      style={{ backgroundColor: "#f9f4f0" }}
    >
      <Card.Body>
        <div className="border-bottom pb-2 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title>
              <div>
                {/* <div>{details.orders.staffName}</div> */}
                <div>{details.selectedTable}</div>
              </div>
            </Card.Title>
            <Card.Subtitle
              className="mb-2"
              style={{ fontSize: "1.25rem", color: "#6c4c41" }}
            >
              Order: {details.orderId}
            </Card.Subtitle>
          </div>
        </div>
        {details.orderItemDetails.map((item) => (
           <div key={item.orderItemId} className="d-flex justify-content-between align-items-center mb-2">
           <div style={{ flex: 3 }}>{item.productName}</div>
           <div style={{ flex: 1, textAlign: "center" }}>x {item.quantity > 0 ? item.quantity : `${item.unitInKg} kg`}</div>
           <div style={{ flex: 1, textAlign: "right", whiteSpace: "nowrap" }}>Nrs: {item.quantity === 0 ? (item.unitInKg * item.priceInKg).toFixed(2) : (item.price * item.quantity).toFixed(2)}</div>
         </div>
       ))}
        <hr />
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>Total:</div>
          <div>
            <strong>Nrs: {details.totalAmount.toFixed(2)}</strong>
          </div>
        </div>
        <hr />
        <p> Payment: Pending</p>
        {details.status === "Completed" ? (
          <Button variant="danger" size="sm" className="flex-grow-1" disabled>
            Payment Pending
          </Button>
        ) : (
          <div className="d-flex justify-content-between mt-4">
            <Button
              size="sm"
              className="flex-grow-1 mr-2"
              onClick={() => handleAddOrder(details.selectedTable)}
              style={{ backgroundColor: "#a27e57" }}
            >
              Add Order
            </Button>
            <Button
              size="sm"
              className="flex-grow-1"
              onClick={() =>
                handleCompleteOrder(details.orderId, details.selectedTable)
              }
              style={{ backgroundColor: "#7f5b39" }}
            >
              Complete Order
            </Button>
          </div>
        )}
        <div
          className="mt-4 p-3"
          style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <strong>Created Date & Time</strong>
            </div>
            {details.eventTimeStamp.completedDate && (
              <div>
                <strong>Completed Time</strong>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div>{details.eventTimeStamp.createdTime}</div>
            {details.eventTimeStamp.createdDate && (
              <div>{details.eventTimeStamp.completedTime}</div>
            )}
          </div>
          {shouldShowDate && (
            <div className="d-flex justify-content-between">
              <div>{details.eventTimeStamp.createdDate}</div>
              {details.eventTimeStamp.completedDate && (
                <div>{details.eventTimeStamp.completedDate}</div>
              )}
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CreatedOrderCard;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Food } from "../../models/ResponseModels";

interface AddFoodByKgModalProps {
  show: boolean;
  handleClose: () => void;
  food: Food;
  handleSave: (updatedFood: Food) => void;
}

const AddFoodByKgModal: React.FC<AddFoodByKgModalProps> = ({
  show,
  handleClose,
  food,
  handleSave,
}) => {
  const [selectedWeight, setSelectedWeight] = useState<number | null>(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const pricePerKg = food.priceInKg;

  useEffect(() => {
    if (selectedWeight !== null) {
      setTotalPrice((selectedWeight / 1000) * pricePerKg);
    } else {
      setTotalPrice(0);
    }
  }, [selectedWeight]);

  const handleWeightChange = (weight: number) => {
    setSelectedWeight((prevWeight) =>
      prevWeight !== null ? prevWeight + weight : weight
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const weight = value === "" ? null : parseInt(value, 10);
    setSelectedWeight(weight);
  };

  const handleClear = () => {
    setSelectedWeight(null);
    setTotalPrice(0);
  };

  const handleSaveClick = (selectedFood: Food) => {
    if (selectedWeight !== null) {
      const updatedFood = {
        ...selectedFood,
        totalPrice: totalPrice,
        pricePerKg: pricePerKg,
        unitInKg: selectedWeight / 1000,
      };
      console.log("unitInkg", updatedFood.unitInKg);
      handleSave(updatedFood);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Food Item by Weight</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={food.name} readOnly />
            <Form.Label>Price per Kg</Form.Label>
            <Form.Control type="text" value={`Nrs. ${pricePerKg}`} readOnly />
          </Form.Group>
          <Form.Group>
            <div
              className="d-flex justify-content-between mb-3"
              role="group"
              aria-label="Weight options"
            >
              {[1, 5, 50, 100, 200, 500, 1000].map((weight) => (
                <div key={weight} style={{ marginRight: "5px" }}>
                  <Button
                    variant="secondary"
                    onClick={() => handleWeightChange(weight)}
                  >
                    {weight}g
                  </Button>
                </div>
              ))}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Weight (grams)</Form.Label>
            <Form.Control
              type="number"
              value={selectedWeight !== null ? selectedWeight : ""}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Total Price</Form.Label>
            <Form.Control
              type="text"
              value={`Nrs. ${totalPrice.toFixed(2)} (${(
                (selectedWeight ?? 0) / 1000
              ).toFixed(2)} kg)`}
              readOnly
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="warning" onClick={handleClear}>
          Clear
        </Button>
        <Button variant="primary" onClick={() => handleSaveClick(food)}>
          Add to Cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFoodByKgModal;

import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { CartItem } from "../../models/ResponseModels";
import { SpinnerLoading } from "../Utils/SpinnerLoading";

interface CartItemProps {
  item: CartItem;
  onIncrement: (
    quantity: number,
    itemName: string,
    itemId: number,
    price: number
  ) => void;
  onDecrement: (
    quantity: number,
    itemName: string,
    itemId: number,
    price: number
  ) => void;
  loading: boolean; // Added to indicate loading state
}

const CartItemComponent: React.FC<CartItemProps> = React.memo(
  ({ item, onIncrement, onDecrement, loading }) => {
    console.log("Rendering item:", item); // This log will help you see when this item re-renders

    let unitInKg = item.unitInKg === undefined ? item.unitInKg : 0;
    unitInKg = item.unitInKg;

    return (
      <tr>
        <td>{item.name}</td>
        {item.unitInKg !== 0 && item.quantity <= 0 ? (
          <td> Rs {item.priceInKg.toFixed(2)}</td>
        ) : (
          <td> Rs {item.price.toFixed(2)}</td>
        )}

        <td>
          {loading ? (
            <SpinnerLoading /> // Simple loading text; replace with a spinner if preferred
          ) : (
            <>
              {item.unitInKg !== 0 && item.quantity <= 0 ? (
                <>
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() =>
                      onDecrement(1, item.name, item.id, item.price)
                    }
                  >
                    <FaMinus />
                  </button>
                  <span className="mx-2"> kg {unitInKg.toFixed(2)}</span>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() =>
                      onDecrement(item.quantity, item.name, item.id, item.price)
                    }
                  >
                    <FaMinus />
                  </button>
                  <span className="mx-2">{item.quantity}</span>
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() =>
                      onIncrement(item.quantity, item.name, item.id, item.price)
                    }
                  >
                    <FaPlus />
                  </button>
                </>
              )}
            </>
          )}
        </td>
        {item.unitInKg !== 0 && item.quantity <= 0 ? (
          <td>${(unitInKg * item.priceInKg).toFixed(2)}</td>
        ) : (
          <td>${(item.price * item.quantity).toFixed(2)}</td>
        )}
      </tr>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.item.quantity === nextProps.item.quantity &&
      prevProps.item.id === nextProps.item.id &&
      prevProps.loading === nextProps.loading // Ensure component re-renders if loading state changes
    );
  }
);

export default CartItemComponent;

/**
 * FoodService.ts
 * Represents the Model in the MVVM architecture, responsible for managing business logic and API calls.
 * It interacts with the backend server to fetch, create, update, or delete food-related data.
 * This service ensures that the ViewModel remains agnostic of the data source and can focus on application logic.
 */

import { String } from "lodash";
import { handleResponse } from "../../util/ResponseHandler";
import { Food } from "../../models/ResponseModels";

const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

export class FoodService {
  static async getFoods<T>(searchValue: string, action: string) {
    console.log("Fetching getFoods");

    try {
      const response = await fetch(
        `${APP_API_URL}/api/food/${searchValue}?action=${action}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      console.error("Failed to fetch foods:", error);

      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async fetchCategories<T>() {
    try {
      console.log("Fetching categories");

      const response = await fetch(`${APP_API_URL}/api/categories`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      console.error("Failed to fetch categories:", error);

      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async saveUpdatedFood<T>(updatedFood: Food) {
    try {
      const updatedFoodReq = {
        name: updatedFood.name,
        price: updatedFood.price,
        description: updatedFood.description,
        image: updatedFood.img,
      };

      const img = updatedFood.img || "";
      const name = updatedFood.name || "";
      const price = updatedFood.price || 0;
      const priceKg = updatedFood.priceInKg || 0;
      const priceKgTwo = updatedFood.priceKgTwo || 0;
      // const description = "";
      // const calories = 0;
      // const ingredients = "";

      console.log("update food req: ", updatedFoodReq);
      const response = await fetch(
        `${APP_API_URL}/api/food/update?id=${updatedFood.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            price,
            priceKg,
            priceKgTwo,
            img,
            // description,
            // calories,
            // ingredients,
          }),
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async addNewFood<T>(addNewFood: Food) {
    try {
      const addNewFoodReq = {
        name: addNewFood.name,
        price: addNewFood.price,
        description: addNewFood.description,
        image: addNewFood.img,
      };

      const img = addNewFood.img || "";
      const name = addNewFood.name || "";
      const categoryName = addNewFood.category_name || "";
      const categoryId = addNewFood.category_id || 0;
      const price = addNewFood.price || 0;
      const priceKg = addNewFood.priceInKg || 0;
      const priceKgTwo = addNewFood.priceKgTwo || 0;
      // const description = "";
      // const calories = 0;
      // const ingredients = "";

      console.log("update food req: ", addNewFoodReq);
      const response = await fetch(`${APP_API_URL}/api/createFood`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          categoryName,
          categoryId,
          price,
          priceKg,
          priceKgTwo,
          img,
          // description,
          // calories,
          // ingredients,
        }),
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      return handleResponse<T>(new Error(error.msg));
    }
  }
}

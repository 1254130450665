import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  ApiResponseState,
  CreatedOrdersInfo,
} from "../../models/ResponseModels";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { isNotNullOrEmpty } from "../../util/DataHelpers";
import MessageModal from "../Utils/MessageModal";
import DatePicker from "react-datepicker";
import CreatedOrderCard from "../common/CreatedOrderCard";

interface OrderHistoryComponentProps {
  createdOrderRes: ApiResponseState<CreatedOrdersInfo[]>;
  loadCreatedOrdersByDate: (seat: string) => void;
  onCloseModel: () => void;
  handleCompleteOrderAction: (orderId: number, seatName: string) => void;
  handleAddOrderAction: (seatName: string) => void;
}

export const CreatedOrderHistory: React.FC<OrderHistoryComponentProps> = ({
  createdOrderRes,
  onCloseModel,
  loadCreatedOrdersByDate,
  handleCompleteOrderAction,
  handleAddOrderAction,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorMessage, setErrMessage] = useState("");

  useEffect(() => {
    loadCreatedOrdersByDate("");
  }, []);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      loadCreatedOrdersByDate(date.toISOString().split("T")[0]);
    }
  };

  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  const handleBackGroundColor = () => {};

  const onCloseModalAction = () => {
    setShowDatePicker(true);
    setShowMessageModal(false);
    setErrMessage("");
  };

  useEffect(() => {
    if (isNotNullOrEmpty(createdOrderRes.error)) {
      setShowMessageModal(true);
      setErrMessage(createdOrderRes.error || "Error occurred");
    } else if (!createdOrderRes.res || createdOrderRes.res.length === 0) {
      setShowMessageModal(true);
      setErrMessage("No Order Found. Please Select Another Date");
    } else if (createdOrderRes) {
      setShowMessageModal(false);
    }
  }, [createdOrderRes]);

  if (createdOrderRes.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading />
      </div>
    );
  }

  let totalAmount = 0;
  if (createdOrderRes.res != null) {
    totalAmount = createdOrderRes.res!!.reduce(
      (acc, order) => acc + order.totalAmount,
      0
    );
  }

  return (
    <>
      <Container className="text-center my-3">
        {showDatePicker && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
          />
        )}
        <Button
          variant="btn btn-secondary"
          onClick={toggleDatePicker}
          className="ml-2"
        >
          {showDatePicker ? "Hide Calendar" : "Show Calendar"}
        </Button>
      </Container>

      <div className="d-flex justify-content-between flex-wrap mb-3">
        <div className="total-box p-2">
          <h3 className="m-0">
            Total:{" "}
            <span className="font-weight-bold">{totalAmount.toFixed(2)}</span>
          </h3>
        </div>
      </div>
      <MessageModal
        show={showMessageModal}
        onClose={onCloseModalAction}
        message={errorMessage}
      />
      {createdOrderRes.res && createdOrderRes.res.length > 0 && (
        <Row>
          {createdOrderRes.res?.map((order: CreatedOrdersInfo) => (
            <Col
              key={order.eventTimeStamp.createdTime}
              sm={12}
              md={6}
              lg={4}
              xl={3}
            >
              <CreatedOrderCard
                details={order}
                handleAddOrder={handleAddOrderAction}
                handleCompleteOrder={handleCompleteOrderAction}
                shouldShowDate={true}
                cardBackgroundColor={"#a1a1a1"}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

import { useCallback, useEffect, useState } from "react";
import {
  CreateExpenseResponse,
  ExpensesDetail,
  GetExpensesByDateResponse,
} from "../../models/ResponseModels";
import { ExpensesService } from "../api/ExpensesService";
import { useNavigate } from "react-router-dom";

export const useExpensesViewModel = () => {
  const [expensesRes, setExpensesRes] = useState<ExpensesDetail[]>([]);
  const [createExpensesRes, setCreateExpensesRes] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
 // const navigate = useNavigate();

  // Load expenses by date range
  const loadGetExpensesByDateRange = useCallback(async (date: string) => {
    setLoading(true);
    try {
      const response =
        await ExpensesService.getExpensesByDateRange<GetExpensesByDateResponse>(
          date
        );
      if (response.status === "success" && response.data) {
        if(response.data.payload === null){
          setExpensesRes([]);
        }else {
          setExpensesRes(response.data.payload);
        }
        
      } else if (response.status === "error") {
        setError(response.error || "Failed to fetch expenses");
      }
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching expenses");
    }
    setLoading(false);
  }, []);

  // Create expenses
  const createExpenses = useCallback(
    async (
      description: string,
      amount: number,
      quantity: number,
      date: string
    ) => {
      setLoading(true);
      try {
        const response =
          await ExpensesService.saveExpenses<CreateExpenseResponse>(
            description,
            amount,
            quantity,
            date
          );
        if (response.status === "success" && response.data) {
          setCreateExpensesRes(response.data.payload);
        } else if (response.status === "error") {
          setError(response.error || "Failed to create expense");
        }
      } catch (error: any) {
        setError(error.message || "An error occurred while creating expense");
      }
      setLoading(false);
    },
    []
  );

  // Save expenses using createExpenses
  const saveExpenses = useCallback(
    (description: string, amount: number, quantity: number, date: string) => {
      createExpenses(description, amount, quantity, date);
    },
    [createExpenses]
  ); // Depend on createExpenses to refresh when it changes

  useEffect(() => {
    loadGetExpensesByDateRange(""); // Load initial expenses if needed
  }, [loadGetExpensesByDateRange]);

  const onCloseModel = useCallback(() => {
    // navigate(`/home`);
    // setError("");
  }, []);

  const clearAutoCloseMessage = useCallback(() => {
    setCreateExpensesRes("");
    setError("");
  }, []);

  return {
    loadGetExpensesByDateRange,
    expensesRes,
    error,
    loading,
    createExpensesRes,
    saveExpenses,
    onCloseModel,
    clearAutoCloseMessage,
  };
};

import React from "react";
import { Modal } from "react-bootstrap";

interface MessageModalProps {
  show: boolean;
  onClose: () => void;
  message: string;
}

const MessageModal: React.FC<MessageModalProps> = ({
  show = false,
  onClose,
  message,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
    </Modal>
  );
};

export default MessageModal;

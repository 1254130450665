import { Seating } from "../../components/TableSeat/Seating";
import { useSeatViewModel } from "../viewModel/SeatViewModel";

export const SeatViewController = () => {
  console.log("SeatViewController rendering"); // Check if this logs

  const { seats, error, loading, selectSeat } = useSeatViewModel();

  return (
    <Seating
      seats={seats}
      error={error}
      loading={loading}
      selectSeat={selectSeat}
    />
  );
};

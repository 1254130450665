import { useNavigate } from "react-router-dom";
import CartPage from "../../components/CartPage/cartPage";
import { useCart } from "../../context/CartContext";
import { useSeatContext } from "../../context/SeatContext";
import { PaymentInfo } from "../../models/ResponseModels";
import { useOrderViewModel } from "../viewModel/OrderViewModel";
import { useCallback } from "react";
import {
  checkQuantityAction,
  isNotNullOrEmpty,
  isValidNumber,
} from "../../util/DataHelpers";

export const CartViewController = () => {
  console.log("SeatViewController rendering"); // Check if this logs
  const navigate = useNavigate();

  const { cart, handleQtyUpdateAction, totalAmount, updateActionState } =
    useCart();
  const { seatName } = useSeatContext();
  const {
    completeOrder,
    completeOrderState,
    saveOrderId,
    existingBySeatNameState,
    updateOrder,
    updateOrderState,
  } = useOrderViewModel();

  const completeOrderbnyId = (
    paymentInfo: PaymentInfo,
    seatName: string,
    orderId: number
  ) => {
    completeOrder(paymentInfo, seatName, orderId);
  };

  const setShouldDisplayModel = () => {
    return (
      isNotNullOrEmpty(completeOrderState.error) ||
      isNotNullOrEmpty(existingBySeatNameState.error)
    );
  };

  const onCloseModel = useCallback(() => {
    navigate(`/home`);
  }, [navigate]);

  const updateActionHandler = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    console.log("cartVC saveOrderId : ", saveOrderId);

    // if saveOrderId means existing order is present we make
    // api call for presistance else would just update the local state in cart context

    if (isValidNumber(saveOrderId)) {
      console.log("cartVC valid saveOrderId : ", saveOrderId);

      console.log(
        "updateActionState success and calling updateOrder : ",
        updateActionState
      );

      const updateAction = checkQuantityAction(updatedQty);

      updateOrder(
        saveOrderId,
        itemId,
        updatedQty,
        itemName,
        updateAction,
        0,
        0
      );
    } else {
      console.log("cartVC Invalid saveOrderId : ", saveOrderId);

      handleQtyUpdateAction(updatedQty, itemName, itemId, price);
    }
  };

  return (
    <CartPage
      saveOrderId={saveOrderId}
      cart={cart}
      error={""}
      loading={false}
      handleQtyUpdateAction={updateActionHandler}
      totalAmount={totalAmount}
      seatName={seatName}
      handleCompleteOrder={completeOrderbnyId}
      onCloseModel={onCloseModel}
      shouldDisplayModelFlag={setShouldDisplayModel}
      completeOrderState={completeOrderState}
      existingOrdersBySeatRes={existingBySeatNameState}
      updateOrderState={updateOrderState}
    />
  );
};

import { handleResponse } from "../../util/ResponseHandler";

const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

export class ExpensesService {
  static async saveExpenses<T>(
    description: string,
    amount: number,
    quantity: number,
    expensesDate: string
  ) {
    try {
      const response = await fetch(`${APP_API_URL}/api/createExpenses`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ description, amount, quantity, expensesDate }),
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async getExpensesByDateRange<T>(date: string) {
    try {
      const response = await fetch(
        `${APP_API_URL}/api/getExpenses?date=${date}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      return handleResponse<T>(new Error(error.msg));
    }
  }
}

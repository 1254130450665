import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Food } from "../../models/ResponseModels";
import ReviewModal from "./ReviewModal";

interface AddNewFoodModalProps {
  show: boolean;
  handleClose: () => void;
  handleAddNewFood: (food: Food) => void; // This might be used to actually save the changes after review
}

const AddNewFoodModal: React.FC<AddNewFoodModalProps> = ({
  show,
  handleClose,
  handleAddNewFood,
}) => {
  const [updatedName, setUpdatedName] = useState("");
  const [updatedCategoryId, setCategoryUpdatedId] = useState(0);
  const [updatedCategoryName, setCategoryUpdatedName] = useState("");
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [updatedPriceKg, setUpdatedPriceKg] = useState(0);
  const [updatedPriceKgTwo, setUpdatedPriceKgTwo] = useState(0);
  const [updatedDescription, setUpdatedDescription] = useState("");
  // const [updatedIngredients, setUpdatedIngredients] = useState(
  //   food.ingredients
  // );
  // const [updatedCalories, setUpdatedCalories] = useState(food.calories);
  const [updatedImage, setUpdatedImage] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false); // Manage the review modal visibility

  // Function to convert image to base64
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (loadEvent: any) {
        setUpdatedImage(loadEvent.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleReview = () => {
    setShowReviewModal(true); // Open the review modal
  };

  const handleReviewClose = () => {
    setShowReviewModal(false); // Close the ReviewModal
  };

  const handleConfirmChanges = () => {
    // Construct the updated food object to pass back
    const updatedFood: Food = {
      id: 0,
      name: updatedName,
      price: updatedPrice,
      priceInKg: updatedPriceKg,
      priceKgTwo: updatedPriceKgTwo,
      description: updatedDescription,
      img: updatedImage,
      category_name: updatedCategoryName,
      category_id: updatedCategoryId,
      quantity: 0,
      totalPrice: 0,
      unitInKg: 0,
    };
    handleAddNewFood(updatedFood); // Call the parent callback with updated food
    handleReviewClose();
  };

  return (
    <>
      <Modal show={show && !showReviewModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "#d2a293" }}>
          <Modal.Title>Update Food Item</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#d2a293" }}>
          <Form>
            <Form.Group controlId="foodName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={""}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="categoryId">
              <Form.Label>Category Id</Form.Label>
              <Form.Control
                type="text"
                defaultValue={""}
                onChange={(e) =>
                  setCategoryUpdatedId(parseFloat(e.target.value))
                }
              />
            </Form.Group>
            <Form.Group controlId="categoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={""}
                onChange={(e) => setCategoryUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="foodPrice">
              <Form.Label>Price per piece</Form.Label>
              <Form.Control
                type="number"
                defaultValue={0}
                onChange={(e) => setUpdatedPrice(parseFloat(e.target.value))}
              />
            </Form.Group>
            <Form.Group controlId="foodPriceKg">
              <Form.Label>Price per kg</Form.Label>
              <Form.Control
                type="number"
                defaultValue={0}
                onChange={(e) => setUpdatedPriceKg(parseFloat(e.target.value))}
              />
            </Form.Group>
            <Form.Group controlId="foodPriceKgTwo">
              <Form.Label>Price per kg (Nepali gheu)</Form.Label>
              <Form.Control
                type="number"
                defaultValue={0}
                onChange={(e) =>
                  setUpdatedPriceKgTwo(parseFloat(e.target.value))
                }
              />
            </Form.Group>
            <Form.Group controlId="foodDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                defaultValue={"" || "empty"}
                onChange={(e) => setUpdatedDescription(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group controlId="foodIngredients">
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                type="text"
                defaultValue={food.ingredients || "empty"}
                onChange={(e) => setUpdatedIngredients(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="foodCalories">
              <Form.Label>Calories</Form.Label>
              <Form.Control
                type="number"
                defaultValue={food.calories || 0}
                onChange={(e) => setUpdatedCalories(parseInt(e.target.value))}
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={convertToBase64}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f7eacf" }}>
          <Button variant="btn btn-danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="btn btn-dark" onClick={handleReview}>
            Review Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ReviewModal Component */}
      <ReviewModal
        show={showReviewModal}
        handleClose={() => setShowReviewModal(false)}
        handleConfirm={handleConfirmChanges}
        updatedFood={{
          name: updatedName,
          price: updatedPrice,
          priceKg: updatedPriceKg,
          priceKgTwo: updatedPriceKgTwo,
          description: updatedDescription,
          // ingredients: updatedIngredients,
          // calories: updatedCalories,
          img: updatedImage,
        }}
      />
    </>
  );
};

export default AddNewFoodModal;

import React, { createContext, useContext, useState, ReactNode } from "react";

type OrderContextType = {
  saveOrderId: number;
  setSaveOrderId: React.Dispatch<React.SetStateAction<number>>;
  clearSaveOrderId: () => void;
};

const OrderContext = createContext<OrderContextType | undefined>(undefined);

interface OrderContextProviderProps {
  children: ReactNode;
}

export const OrderContextProvider: React.FC<OrderContextProviderProps> = ({
  children,
}) => {
  const [saveOrderId, setSaveOrderId] = useState(0);

  const clearSaveOrderId = () => {
    setSaveOrderId(0);
  };

  return (
    <OrderContext.Provider
      value={{
        saveOrderId,
        setSaveOrderId,
        clearSaveOrderId,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error(
      "useOrderContext must be used within a OrderContextProvider"
    );
  }
  return context;
};

import React from 'react';

interface InputProps {
  title?: string; // Make the title prop optional
  type: string;
  className: string;
  name: string;
  autoComplete: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  title,
  type,
  className,
  name,
  autoComplete,
  onChange
}) => {
  return (
    <div>
      {title && <label>{title}</label>} {/* Render the title label if title is provided */}
      <input
        type={type}
        className={className}
        name={name}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
import { CartItem, OrderDetails, OrderItem } from "../models/ResponseModels";

export interface OrderTotals {
  totalOfAllOrders: number;
  totalOfPaidOrders: number;
}

// export const calculateOrderTotals = (
//   data: ExistingCheckoutResponse
// ): OrderTotals => {
//   let totalOfAllOrders = 0;
//   let totalOfPaidOrders = 0;

//   for (const orders of Object.values(data.payload)) {
//     if (orders) {
//       for (const order of orders) {
//         totalOfAllOrders += order.totalAmount;
//         if (order.paymentStatus === "Completed") {
//           totalOfPaidOrders += order.totalAmount;
//         }
//       }
//     }
//   }

//   return { totalOfAllOrders, totalOfPaidOrders };
// };

export function formatName(firstName: string, lastName: string): string {
  // Ensure that the first name is capitalized
  const formattedFirstName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  // Get the first character of the last name, capitalize it, and append a period
  const initialOfLastName = lastName.charAt(0).toUpperCase() + ".";

  // Return the formatted name
  return `${formattedFirstName} ${initialOfLastName}`;
}

export function orderItemsToCartItems(
  orderItems: OrderItem[] | undefined
): CartItem[] {
  if (!orderItems) {
    console.log("No order items provided to orderItemsToCartItems");
    return [];
  }
  return orderItems.map((orderItem) => ({
    id: orderItem.orderItemId,
    name: orderItem.productName,
    price: orderItem.price,
    quantity: orderItem.quantity,
    category_name: "",
    totalPrice: 0,
    priceInKg: orderItem.priceInKg,
    unitInKg: orderItem.unitInKg,
  }));
}

export function isNullOrEmpty(str: string | null | undefined): boolean {
  return !str || str.trim() === "";
}

export function isNotNullOrEmpty(str: string | null | undefined): boolean {
  return str !== null && str !== undefined && str.trim() !== "";
}

export function isValidNumber(value: any) {
  return typeof value === "number" && value > 0;
}

export function checkQuantityAction(qty: number): string {
  if (qty === 0) {
    return "delete";
  } else if (qty > 0) {
    return "update";
  } else {
    return "invalid quantity";
  }
}

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/navbar";
import Footer from "./components/Footer/footer";
import HomePage from "./components/HomePage/homePage";
import { CartProvider } from "./context/CartContext";
import { Login } from "./components/Login/Login";
import ProtectedRoute from "./components/Login/ProtectedRoutes";
import { FoodViewController } from "./model/viewController/FoodViewController";
import { SeatViewController } from "./model/viewController/SeatViewController";
import { useSeatContext } from "./context/SeatContext";
import { CartViewController } from "./model/viewController/CartViewController";
import { OrderViewViewController } from "./model/viewController/OrderHistoryViewController";
import { UpdateFoodViewController } from "./model/viewController/UpdateFoodViewController";
import { CreatedOrderViewViewController } from "./model/viewController/CreatedOrderViewController";
import { useExpensesViewModel } from "./model/viewModel/ExpensesViewModel";
import Expense from "./components/expenses/Expenses";
import SaveExpense from "./components/expenses/SaveExpenses";
import DashboardPage from "./components/Dashboard/Dashboard";

function App() {
  const { seatName } = useSeatContext();

  const {
    loadGetExpensesByDateRange,
    error,
    loading,
    expensesRes,
    createExpensesRes,
    saveExpenses,
    onCloseModel,
    clearAutoCloseMessage,
  } = useExpensesViewModel();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Router>
        <CartProvider>
          <Navbar selectedSeat={seatName} />
          <div className="flex-grow-1" style={{ backgroundColor: '#feebd2' }}>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/login" element={<Login />} />

              {/*ProtectedRoute as element */}
              <Route path="/search" element={<ProtectedRoute element={<FoodViewController />} />} />
            <Route path="/search/:seatName" element={<ProtectedRoute element={<FoodViewController />} />} />
            <Route path="/menu" element={<ProtectedRoute element={<DashboardPage />} />} />
            <Route path="/cart" element={<ProtectedRoute element={<CartViewController />} />} />
            <Route path="/seating" element={<ProtectedRoute element={<SeatViewController />} />} />

  

              <Route
                path="/expenses"
                element={
                  <ProtectedRoute 
                  element={
                  <Expense
                    expensesRes={expensesRes}
                    error={error}
                    loading={loading}
                    loadGetExpensesByDateRange={loadGetExpensesByDateRange}
                    clearAutoCloseMessage={clearAutoCloseMessage}
                    />
                    }
                  />
                }
              />
              
              <Route
                path="/save-expenses"
                element={
                  <ProtectedRoute 
                    element={
                      <SaveExpense
                        saveExpenses={saveExpenses}
                        error={error}
                        loading={loading}
                        createExpensesRes={createExpensesRes}
                        onCloseModel={onCloseModel}
                        shouldDisplayModelFlag={() => false}
                      />
                    }
                  />
                }
              />

                {/*ProtectedRoute as element */}
              <Route path="/completeOrders" element={<ProtectedRoute adminOnly element={<OrderViewViewController />} />} />
              <Route path="/pendingOrders" element={<ProtectedRoute adminOnly element={<CreatedOrderViewViewController />} />} />
              <Route path="/updateFood" element={<ProtectedRoute adminOnly element={<UpdateFoodViewController />} />} />
            </Routes>
          </div>
          <Footer />
        </CartProvider>
      </Router>
    </div>
  );
}
export default App;

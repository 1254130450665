import React, { useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import logo from '../../Images/cafeImages/logo33.jpeg'; 

const cardData = [
    {
        id: 1,
        title: 'Menu',
        imgSrc: logo,
        route: '/search'
    },
    {
        id: 2,
        title: 'Table Order',
        imgSrc: logo,
        route: '/seating'
    },
    {
        id: 3,
        title: 'Counter Order',
        imgSrc: logo,
        route: '/seating'
    },
    {
        id: 4,
        title: 'Pre Order',
        imgSrc: logo,
        route: '/seating'
    },  
];

const DashboardPage = () => {

    const navigate = useNavigate();
    const handleSelect = (route: To) => {
      navigate(route);
    };
  
  return (
        <div className="container mt-5">
            <h1 className="text-center mb-4"
             style={{ 
                backgroundColor: "#f5f5dc",
                display: "flex", // Enables flexbox
                justifyContent: "center", // Centers content horizontally
                alignItems: "center", // Centers content vertically
                zIndex: 1050, // Use a high value here to ensure it's on top of other content
                fontFamily: "Serif, sans-serif",
                fontSize: 30, // Google Font
                //fontWeight: "700", // Bold
                color: "#333", // Dark grey for better readability
                padding: "20px 10px", // Increased padding
                marginBottom: "20px", // Added margin below the banner
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional: Adds a subtle shadow to make the div stand out
                }}>Dashboard</h1>

            <div className="row">
            <div className="container mt-4">
            <div className="row">
            {cardData.map((card, index) => (
                  <div key={index} className={`col-12 col-sm-6 col-md-3 mb-4`}>
                      <div className="card">
                          <img src={card.imgSrc} className="card-img-top" alt={card.title} />
                          <div className="card-body">
                              <h5 className="card-title text-center" >{card.title}</h5>
                              <button onClick={() => handleSelect(card.route)} className="btn btn-secondary w-100">Select</button>
                          </div>
                      </div>
                  </div>
              ))}
            </div>
        </div>
            </div>
        </div>
    );
};

export default DashboardPage;

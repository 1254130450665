import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="container-fluid text-white py-5"
    style={{ 
      backgroundColor: '#eedaba',
      backgroundImage: 'url(/path-to-your-image.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      <div className="row text-center" style={{ color: "black" }}>
        <div className="col-md-4 mb-3">
          <h5 className="font-weight-bold">Opening Hours</h5>
          <p >Monday - Friday: 7:00 AM - 8:00 PM</p>
          <p>Saturday - Sunday: 7:00 AM - 8:00 PM</p>
        </div>
        <div className="col-md-4 mb-3">
          <h5 className="font-weight-bold">Contact Us</h5>
          <p>Email: <a href="mailto:info@laxmimithai.com" className="text-black">info@laxmimithai.com</a></p>
          <p>Phone: 01-6611118/ 01-6610165</p>
          <p>Address: Suryabinayak, Bhaktapur 44800, Nepal</p>
        </div>
        <div className="col-md-4 mb-3">
          <h5 className="font-weight-bold">Follow Us</h5>
          <div className="d-flex justify-content-center">
            <a href="#" className="text-white mx-2"><i className="fab fa-facebook-f"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-twitter"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-instagram"></i></a>
            <a href="#" className="text-white mx-2"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>
      <div className="row" style={{ color: "black" }}>
        <div className="col text-center">
          <p className="mb-0">&copy; {new Date().getFullYear()} Laxmi Mithai. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

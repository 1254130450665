import { useCallback, useEffect, useState } from "react";
import {
  CartItem,
  CompleteOrderResponse,
  CreateOrderResponse,
  ExistingOrderBySeatResponse,
  ExistingOrderDetails,
  PaymentInfo,
  ResExistingOrderBySeatState,
  ResponseCompleteState,
  ResponseState,
  ResponseUpdateItemState,
  UpdateOrderResponse,
} from "../../models/ResponseModels";
import { OrderService } from "../api/OrderService";
import { useCart } from "../../context/CartContext";
import { useOrderContext } from "../../context/OrderContext";
import { useSeatContext } from "../../context/SeatContext";
import { orderItemsToCartItems } from "../../util/DataHelpers";

export const useOrderViewModel = () => {
  const { seatName } = useSeatContext();

  const [responseState, setResponseState] = useState<ResponseState>({
    loading: false,
    error: "",
    orderId: 0,
  });

  const [completeOrderState, setCompleteOrderState] =
    useState<ResponseCompleteState>({
      loading: false,
      error: "",
      response: "",
    });

  const [updateOrderState, setUpdateOrderState] =
    useState<ResponseUpdateItemState>({
      loading: false,
      error: "",
      response: "",
    });

  const [existingBySeatNameState, setExistingBySeatNameState] =
    useState<ResExistingOrderBySeatState>({
      loading: false,
      error: "",
      response: null,
    });
  const { clearCart, updateActionState, setCart } = useCart();
  const { setSaveOrderId, saveOrderId, clearSaveOrderId } = useOrderContext();

  const saveOrder = useCallback(
    async (cartItems: CartItem[], seatName: string) => {
      console.log("save order from VM items  : ", cartItems);
      setResponseState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await OrderService.createOrder<CreateOrderResponse>(
          cartItems,
          seatName
        );
        console.log(" response from handler res ", response);
        if (response.status === "success" && response.data) {
          clearCart();
          setSaveOrderId(response.data.payload);
          setResponseState((prev) => ({
            ...prev,
            loading: false,
            orderId: response.data.payload,
          }));
        } else if (response.status === "error") {
          console.log(" status == error true ", response);

          setResponseState((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        console.log("error from save order : ", error);
        setResponseState((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [responseState]
  );

  const completeOrder = useCallback(
    async (paymentInfo: PaymentInfo, seatName: string, orderId: number) => {
      console.log("complete order from VM items  : ", paymentInfo);
      setCompleteOrderState((prev) => ({
        ...prev,
        loading: true,
      }));
      clearSaveOrderId();
      try {
        const response =
          await OrderService.completeOrder<CompleteOrderResponse>(
            paymentInfo,
            seatName,
            orderId
          );
        console.log(" response from handler res ", response);
        if (response.status === "success" && response.data) {
          clearCart();
          setCompleteOrderState((prev) => ({
            ...prev,
            loading: false,
            response: response.data.payload,
          }));
        } else if (response.status === "error") {
          console.log(" status == error true ", response);

          setCompleteOrderState((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        console.log("error from save order : ", error);
        setCompleteOrderState((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [completeOrderState]
  );

  const updateOrder = useCallback(
    async (
      orderId: number,
      orderItemId: number,
      quantity: number,
      productName: string,
      updateAction: string,
      priceInKg: number,
      unitInKg: number
    ) => {
      console.log("uodate order from VM items  : ", orderItemId);
      setUpdateOrderState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await OrderService.updateOrder<UpdateOrderResponse>(
          orderId,
          orderItemId,
          quantity,
          productName,
          updateAction,
          priceInKg,
          unitInKg
        );
        console.log(" update response from handler res ", response);
        if (response.status === "success") {
          console.log("update res : ", response.data.payload);
          setUpdateOrderState((prev) => ({
            ...prev,
            loading: false,
            response: response.data.payload,
          }));
        } else if (response.status === "error") {
          console.log(" status == error true ", response);
          setUpdateOrderState((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        console.log("error from save order : ", error);
        setUpdateOrderState((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [updateOrderState]
  );

  const loadExistingOrderBySeatName = useCallback(
    async (seatName: string) => {
      console.log(
        "loadExistingOrderBySeatName order from VM items  : ",
        seatName
      );
      setExistingBySeatNameState((prev) => ({
        ...prev,
        loading: true,
      }));
      clearSaveOrderId();
      try {
        const response =
          await OrderService.existingOrdersBySeatName<ExistingOrderBySeatResponse>(
            seatName
          );
        console.log(" response from handler res ", response);
        if (response.status === "success") {
          console.log(
            "response success from loadExistingOrderBySeatName ",
            response.data.payload
          );

          console.log(
            "res existing items ",
            response.data.payload.orderItemDetails
          );
          const res: ExistingOrderDetails = {
            orderId: response.data.payload.orderId,
            selectedTable: response.data.payload.selectedTable,
            status: response.data.payload.status,
            cartItems: orderItemsToCartItems(
              response.data.payload.orderItemDetails
            ),
          };
          setSaveOrderId(res.orderId);
          if (res.cartItems != null) {
            setCart(res.cartItems);
          }
          setExistingBySeatNameState((prev) => ({
            ...prev,
            loading: false,
            response: res,
          }));
        } else if (response.status === "error") {
          console.log("status == error true ", response);
          setExistingBySeatNameState((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        console.log("error from save order : ", error);
        setExistingBySeatNameState((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [existingBySeatNameState]
  );

  useEffect(() => {
    if (seatName) {
      loadExistingOrderBySeatName(seatName);
    }
  }, [seatName, updateOrder]);

  return {
    responseState,
    saveOrder,
    completeOrderState,
    completeOrder,
    saveOrderId,
    updateOrder,
    updateOrderState,
    existingBySeatNameState,
  };
};

/**
 * SearchFood.tsx
 * The View component responsible for rendering the UI elements that display the foods and categories.
 * It receives all necessary props from the FoodViewController which includes data and event handlers.
 * This separation allows for better reusability and manageability of the UI logic.
 */

import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  CartItem,
  Food,
  ResExistingOrderBySeatState,
  ResponseState,
  ResponseUpdateItemState,
} from "../../models/ResponseModels";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import MessageModal from "../Utils/MessageModal";
import Cart from "../common/Cart";
import FoodCard from "./FoodCard";
import AutoCloseMessageModal from "../Utils/AutoCloseMessageModal";
import { orderItemsToCartItems } from "../../util/DataHelpers";

interface SearchFoodComponentProps {
  totalAmount: number;
  foods: Food[];
  categories: string[];
  error: string;
  loading: boolean;
  selectedSeat: string;
  onSearchFoodClick: (searchValue: string, action: string) => void;
  addToCart: (item: CartItem) => void;
  cartItems: CartItem[];
  handleQtyUpdateAction: (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number,
    priceInKg: number,
    unitInKg: number
  ) => void;
  saveOrder: (cartItems: CartItem[], seatName: string) => void;
  saveOrderResponseState: ResponseState;
  onCloseModel: () => void;
  shouldDisplayModelFlag: () => boolean;
  updateOrderState: ResponseUpdateItemState;
  existingOrdersBySeatRes: ResExistingOrderBySeatState;
}

const SearchFood: React.FC<SearchFoodComponentProps> = ({
  selectedSeat,
  foods,
  categories,
  error,
  loading,
  onSearchFoodClick,
  addToCart,
  cartItems,
  handleQtyUpdateAction,
  totalAmount,
  saveOrder,
  saveOrderResponseState,
  onCloseModel,
  shouldDisplayModelFlag,
  updateOrderState,
  existingOrdersBySeatRes,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showModel, setShowModel] = useState<boolean>(false);

  const handleCategoryClick = (category: any) => {
    const action = category === "ALL" ? "ALL" : "category";
    onSearchFoodClick(category, action);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchTerm) {
      onSearchFoodClick(searchTerm, "NAME");
    }
  };

  const placeOrder = () => {
    console.log("save food button trigger");
    saveOrder(cartItems, selectedSeat);
  };

  const shouldDisplayModel = useCallback(() => {
    setShowModel(shouldDisplayModelFlag);
  }, [showModel]);

  const handleOnCloseModel = () => {
    console.log("Modal is being closed");
    setShowModel(false);
    onCloseModel();
  };

  if (
    selectedSeat === null ||
    selectedSeat === undefined ||
    selectedSeat === ""
  ) {
    return (
      <AutoCloseMessageModal
        showInitially={true}
        redirectPath="/seating"
        message={`Please select the seat`}
      />
    );
  }

  const addToCartAction = (updatedFoodItem: Food) => {
    console.log("updatedFoodItem", updatedFoodItem);
    addToCart(updatedFoodItem);
  };

  if (
    saveOrderResponseState.loading ||
    loading ||
    existingOrdersBySeatRes.loading
  ) {
    console.log("is loading saveOrderResponseState : ", saveOrderResponseState);
    console.log(
      "is loading existingOrdersBySeatRes : ",
      existingOrdersBySeatRes
    );
    console.log("is loading loading : ", loading);

    return <SpinnerLoading />;
  }

  if (error || existingOrdersBySeatRes.error || saveOrderResponseState.error)
    return (
      <MessageModal
        show={showModel}
        onClose={handleOnCloseModel}
        message={
          error ||
          existingOrdersBySeatRes.error!! ||
          saveOrderResponseState.error!!
        }
      />
    );

  if (saveOrderResponseState.orderId) {
    return (
      <AutoCloseMessageModal
        showInitially={true}
        redirectPath="/cart"
        message={`Successfully created order with order ID: ${saveOrderResponseState.orderId}`}
      />
    );
  }

  return (
    <div className="container mt-4" style={{ backgroundColor: "" }}>
      <div
        style={{
          backgroundColor: "#f5f5dc",
          display: "flex", // Enables flexbox
          justifyContent: "center", // Centers content horizontally
          alignItems: "center", // Centers content vertically
          zIndex: 1050, // Use a high value here to ensure it's on top of other content
          fontFamily: "Serif, sans-serif",
          fontSize: 16, // Google Font
          fontWeight: "700", // Bold
          color: "#333", // Dark grey for better readability
          padding: "20px 10px", // Increased padding
          marginBottom: "20px", // Added margin below the banner
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Optional: Adds a subtle shadow to make the div stand out
        }}
      >
        <h3 className="responsive-title">Our Menu: {selectedSeat} </h3>
      </div>

      <div className="d-flex justify-content-center mb-3">
        <div className="btn-group" role="group" aria-label="Menu tabs">
          {/* Add an ALL button to clear the category filter */}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => handleCategoryClick("ALL")} // Resets or broadens the filter
          >
            ALL
          </button>
          {categories.map((category, index) => (
            <button
              key={category}
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => handleCategoryClick(category)}
            >
              {category.toUpperCase()}
            </button>
          ))}
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-8">
            <div>
              <input
                type="text"
                placeholder="Search for food..."
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                className="form-control mb-3"
              />
            </div>

            <div className="row row-cols-1 row-cols-md-3 g-4">
              {Array.isArray(foods) &&
                foods.map((food) => (
                  <FoodCard
                    key={food.id}
                    food={food}
                    addToCart={addToCartAction}
                    imageHeight="120px" // Default height passed as a prop
                  />
                ))}
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <Cart
              cartItems={cartItems}
              handleQtyUpdateAction={handleQtyUpdateAction}
              totalAmount={totalAmount}
              placeOrder={placeOrder}
              seatname={selectedSeat}
              updateOrderState={updateOrderState}
              showMessageModel={showModel}
              handleOnCloseModelAction={handleOnCloseModel}
              existingOrderId={existingOrdersBySeatRes.response?.orderId || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFood;

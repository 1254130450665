import React from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";

interface ReviewModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  updatedFood: any;
}

const ReviewModal: React.FC<ReviewModalProps> = ({
  show,
  handleClose,
  handleConfirm,
  updatedFood,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ backgroundColor: "#9DA7AC" }}>
        <Modal.Title>Review Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#A2A8AB" }}>
        <ListGroup>
          <ListGroup.Item>
            <strong>Name:</strong> {updatedFood.name}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Price per piece:</strong> {updatedFood.price}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Price per kg:</strong> {updatedFood.priceKg}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Price per kg (Nepali gheu):</strong> {updatedFood.priceKgTwo}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Description:</strong> {updatedFood.description}
          </ListGroup.Item>
          {/* <ListGroup.Item>
            <strong>Ingredients:</strong> {updatedFood.ingredients}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Calories:</strong> {updatedFood.calories}
          </ListGroup.Item> */}
          {updatedFood.img && (
            <ListGroup.Item style={{ backgroundColor: "#DFD99D" }}>
              <strong>Image Preview:</strong>
              <br />
              <img
                src={updatedFood.img}
                alt="Updated Food"
                style={{ width: "100%" }}
              />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#494C4E" }}>
        <Button variant="btn btn-danger" onClick={handleClose}>
          Close
        </Button>
        <Button variant="btn btn-dark" onClick={handleConfirm}>
          Confirm Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReviewModal;

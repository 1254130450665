import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SeatProvider } from "./context/SeatContext";
import { OrderContextProvider } from "./context/OrderContext";
import { UserProvider } from "./context/UserContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SeatProvider>
      <OrderContextProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </OrderContextProvider>
    </SeatProvider>
  </React.StrictMode>
);

reportWebVitals();

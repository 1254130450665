/**
 * FoodViewModel.ts
 * The ViewModel that serves as an intermediary between the FoodService (Model) and the FoodViewController (View).
 * It manages the state and logic for food and category data operations, such as fetching data and handling user actions.
 * This ViewModel abstracts the data handling (like API calls and state management) away from the View.
 */

import { useCallback, useEffect, useState } from "react";
import { FoodService } from "../api/FoodService";
import {
  ApiResponseState,
  Food,
  GetCategoriesResponse,
  GetFoodsResponse,
  SuccessMessageResponse,
} from "../../models/ResponseModels";
import { useSeatContext } from "../../context/SeatContext";
import { useCart } from "../../context/CartContext";
import { useNavigate } from "react-router-dom";

export const useFoodViewModel = () => {
  console.log("useFoodViewModel executed"); // This should log when the component mounts
  const [foods, setFoods] = useState<Food[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { seatName } = useSeatContext();
  const { addToCart, cart, totalAmount } = useCart();
  const navigate = useNavigate();

  const [updateFoodRes, setUpdateFoodRes] = useState<ApiResponseState<string>>({
    loading: false,
    error: "",
    res: null,
  });

  const [addNewFoodRes, setAddNewFoodRes] = useState<ApiResponseState<string>>({
    loading: false,
    error: "",
    res: null,
  });

  const loadFoods = useCallback(async (searchValue: string, action: string) => {
    setLoading(true);
    try {
      const response = await FoodService.getFoods<GetFoodsResponse>(
        searchValue,
        action
      );
      if (response.status === "success" && response.data) {
        setFoods(response.data.payload);
      } else if (response.status === "error") {
        setError(response.error || "Failed to fetch foods");
      }
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching foods");
    }
    setLoading(false);
  }, []);

  const loadCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response =
        await FoodService.fetchCategories<GetCategoriesResponse>();
      if (response.status === "success" && response.data) {
        setCategories(response.data.payload);
      } else if (response.status === "error") {
        setError(response.error || "Failed to fetch categories");
      }
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching categories");
    }
    setLoading(false);
  }, []);

  const saveUpdatedFoodById = useCallback(async (updatedFood: Food) => {
    setLoading(true);
    try {
      const response =
        await FoodService.saveUpdatedFood<SuccessMessageResponse>(updatedFood);
      if (response.status === "success" && response.data) {
        loadFoods("food", "ALL");
        setUpdateFoodRes((prev) => ({
          ...prev,
          loading: false,
          res: response.data.payload,
        }));
      } else if (response.status === "error") {
        setLoading(false);
        setFoods([]);
        setUpdateFoodRes((prev) => ({
          ...prev,
          loading: false,
          error: response.error || "Failed to fetch foods",
        }));
      }
    } catch (error: any) {
      setUpdateFoodRes((prev) => ({
        ...prev,
        loading: false,
        error: error.message || "An error occurred while creating the order",
      }));
    }
  }, []);

  const addNewFood = useCallback(async (newFood: Food) => {
    setLoading(true);
    try {
      const response =
        await FoodService.addNewFood<SuccessMessageResponse>(newFood);
      if (response.status === "success" && response.data) {
        loadFoods("food", "ALL");
        setAddNewFoodRes((prev) => ({
          ...prev,
          loading: false,
          res: response.data.payload,
        }));
      } else if (response.status === "error") {
        setLoading(false);
        setFoods([]);
        setAddNewFoodRes((prev) => ({
          ...prev,
          loading: false,
          error: response.error || "Failed to fetch foods",
        }));
      }
    } catch (error: any) {
      setAddNewFoodRes((prev) => ({
        ...prev,
        loading: false,
        error: error.message || "An error occurred while creating the order",
      }));
    }
  }, []);

  useEffect(() => {
    console.log("Loading initial data");
    loadFoods("food", "ALL"); // Load all foods on initial render
    loadCategories(); // Load all categories on initial render
  }, [loadFoods, loadCategories]);

  const onCloseModel = useCallback(() => {
    navigate(`/home`);
  }, [navigate]);

  const saveUpdatedFood = useCallback(
    (updatedFood: Food) => {
      console.log("saveUpdatedFood by ID is called api ", updatedFood);
      saveUpdatedFoodById(updatedFood);
    },
    [updateFoodRes]
  );

  return {
    foods,
    categories,
    error,
    loading,
    loadFoods,
    loadCategories,
    seatName,
    addToCart,
    cart,
    totalAmount,
    onCloseModel,
    updateFoodRes,
    saveUpdatedFood,
    addNewFoodRes,
    addNewFood,
  };
};

import React, { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import FoodUpdateModal from "../common/FoodUpdateModal";
import { Food } from "../../models/ResponseModels";

interface FoodItemProps {
  food: Food;
  handleSave: (food: Food) => void;
}

const FoodItem: React.FC<FoodItemProps> = ({ food, handleSave }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <Col sm={12} md={6} lg={3} xl={2} key={food.id} className="mb-4">
      <Card
        className="d-flex flex-column justify-content-between h-100"
        style={{ backgroundColor: "#d2a293", color: "#6c4c41" }}
      >
        <Card.Img
          variant="top"
          src={food.img || "https://via.placeholder.com/150"}
          alt="Food Image"
          className="img-fluid"
          style={{ height: "150px", objectFit: "cover" }}
        />
        <Card.Body className="d-flex flex-column justify-content-between">
          <div>
            <Card.Title
              className="ellipsis"
              style={{
                fontFamily: "Serif, sans-serif",
                fontSize: 16,
                color: "black",
              }}
            >
              {food.name}
            </Card.Title>
            <Card.Text>
              {" "}
              ₹: {food.price || ""} ₹:{food.priceInKg} ₹:{food.priceKgTwo}
            </Card.Text>
          </div>
          <Button
            variant="primary"
            onClick={handleShow}
            style={{
              backgroundColor: "#6c4c41",
              color: "#f8f9fa",
              borderColor: "#6c4c41", // Set the border color
              borderWidth: "2px", // Specify the width of the border
              borderStyle: "solid", // Specify the style of the border (solid, dotted, dashed, etc.)
            }}
          >
            Update
          </Button>
        </Card.Body>
      </Card>
      <FoodUpdateModal
        show={showModal}
        handleClose={handleClose}
        food={food}
        handleSave={handleSave}
      />
    </Col>
  );
};

export default FoodItem;

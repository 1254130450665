// ProtectedRoute component
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
  adminOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, adminOnly = false }) => {
  const { user } = useUser();

  if (!user.userName) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && !user.access) {
    return <Navigate to="/home" replace />;
  }

  return element;
};

export default ProtectedRoute;

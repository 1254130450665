import { useCallback, useState } from "react";
import { OrderService } from "../api/OrderService";
import {
  ApiResponseState,
  CompletedOrdersByDateResponse,
  OrdersInfo,
} from "../../models/ResponseModels";
import { useNavigate } from "react-router-dom";

export const useOrderHistoryViewModel = () => {
  const navigate = useNavigate();

  const [completeOrderRes, setCompleteOrderRes] = useState<
    ApiResponseState<OrdersInfo[]>
  >({
    loading: false,
    error: "",
    res: null,
  });

  const loadCompletedOrdersByDate = useCallback(
    async (date: string) => {
      setCompleteOrderRes((prev) => ({
        ...prev,
        loading: true,
      }));

      try {
        const response =
          await OrderService.completedOrdersByDate<CompletedOrdersByDateResponse>(
            date
          );
        if (response.status === "success" && response.data) {
          setCompleteOrderRes((prev) => ({
            ...prev,
            loading: false,
            res: response.data.payload,
          }));
        } else if (response.status === "error") {
          setCompleteOrderRes((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        setCompleteOrderRes((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [completeOrderRes]
  );

  const onCloseModel = useCallback(() => {
    navigate(`/home`);
  }, [navigate]);

  return { completeOrderRes, onCloseModel, loadCompletedOrdersByDate };
};

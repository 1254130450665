import { CartItem, PaymentInfo } from "../../models/ResponseModels";
import { APIErrorType } from "../../util/ApiResult";
import { handleResponse } from "../../util/ResponseHandler";

const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

export class OrderService {
  static async createOrder<T>(cartItems: CartItem[], seatName: string) {
    try {
      const orderItems = cartItems.map((item) => ({
        FoodID: item.id,
        ProductName: item.name,
        Quantity: item.quantity,
        Price: item.price,
        PriceInKg: item.priceInKg || 0,
        UnitInKg: item.unitInKg || 0,
      }));

      const totalAmount = cartItems.reduce((total: number, item: CartItem) => {
        if (item.unitInKg > 0) {
          total += item.unitInKg * item.priceInKg;
        }
        if (item.quantity > 0) {
          total += item.price * item.quantity;
        }
        return total;
      }, 0); // Initial value for total is 0

      const order = {
        TotalAmount: totalAmount,
        SelectedTable: seatName,
        OrderItems: orderItems,
        StaffName: 1,
      };
      console.log("create order endpoint with paylaod : ", order);
      const response = await fetch(`${APP_API_URL}/api/createOrder`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(order),
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("createOrder error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async completeOrder<T>(
    paymentInfo: PaymentInfo,
    seatName: string,
    orderId: number
  ) {
    try {
      console.log("completeOrder order endpoint with paylaod : ", paymentInfo);
      const response = await fetch(`${APP_API_URL}/api/completeOrder`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ paymentInfo, seatName, orderId }),
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("complete error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async updateOrder<T>(
    orderId: number,
    orderItemId: number,
    quantity: number,
    productName: string,
    updateAction: string,
    priceInKg: number,
    unitInKg: number
  ) {
    try {
      console.log("update order endpoint with paylaod : ", orderId);
      const response = await fetch(
        `${APP_API_URL}/api/orderitems/updatequantity?action=${updateAction}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            orderId,
            orderItemId,
            quantity,
            productName,
            priceInKg,
            unitInKg,
          }),
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("complete error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async existingOrdersBySeatName<T>(seatName: string) {
    try {
      console.log("existingOrders  endpoint with paylaod : ", seatName);
      const response = await fetch(
        `${APP_API_URL}/api/getCreatedOrders?seatName=${seatName}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("complete error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async completedOrdersByDate<T>(date: string) {
    try {
      console.log("completeOrdersByDate  endpoint with paylaod : ", date);
      const response = await fetch(
        `${APP_API_URL}/api/geAllCompletedOrders?date=${date}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("complete error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }

  static async createdOrdersByDate<T>(date: string) {
    try {
      console.log("createdOrdersByDate  endpoint with paylaod : ", date);
      const response = await fetch(
        `${APP_API_URL}/api/geAllCreatedOrders?date=${date}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      return handleResponse<T>(response);
    } catch (error: any) {
      console.log("complete error : ", error);
      return handleResponse<T>(new Error(error.msg));
    }
  }
}

import { useCallback, useEffect, useState } from "react";
import { GetAllSeatsResponse } from "../../models/ResponseModels";
import { SeatService } from "../api/SeatService";
import { useNavigate } from "react-router-dom";
import { useSeatContext } from "../../context/SeatContext";

export const useSeatViewModel = () => {
  console.log("useSeatViewModel executed");
  const navigate = useNavigate();
  const { setSeatName } = useSeatContext();
  const [seats, setSeats] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const loadSeats = useCallback(async () => {
    setLoading(true);
    try {
      const response = await SeatService.getAllSeats<GetAllSeatsResponse>();
      if (response.status === "success" && response.data) {
        setSeats(response.data.payload);
      } else if (response.status === "error") {
        setError(response.error || "Failed to fetch foods");
      }
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching foods");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadSeats();
  }, [loadSeats]);

  const selectSeat = (seat: string) => {
    setSeatName(seat); // Update global state
    navigateToSearchFood(seat);
  };

  const navigateToSearchFood = useCallback(
    (seatName: string) => {
      navigate(`/search/${seatName}`);
    },
    [navigate]
  );

  return {
    seats,
    error,
    loading,
    selectSeat,
  };
};

import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";

interface ModalConfirmationProps {
  show: boolean;
  message: string;
  onClose: () => void;
  onConfirm: (paymentType: string) => void;
  children?: React.ReactNode;
}

export const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  show,
  message,
  onClose,
  onConfirm,
}) => {
  const [paymentType, setPaymentType] = useState("");

  const formatPaymentType = (type: string) => {
    return type.toUpperCase().replace(/\s+/g, "_");
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <Form>
          {["Cash", "Phone Pay", "eSewa", "Credit", "Others"].map((method) => (
            <Form.Check
              type="radio"
              label={method}
              name="paymentType"
              id={method}
              onChange={() => setPaymentType(formatPaymentType(method))}
              key={method}
            />
          ))}
        </Form>
        {/* {children} */}
      </Modal.Body>
      <Modal.Footer>
        <button 
        className="btn btn-secondary"
        style={{ backgroundColor: "#6c757d" }} 
        onClick={onClose}>
          No
        </button>
        <button
          className="btn text-white"
          style={{ backgroundColor: "#a27e57" }}
          onClick={() => onConfirm(paymentType)}
          disabled={!paymentType}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

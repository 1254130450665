import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa"; // Importing a shopping cart icon
import { useCart } from "../../context/CartContext";
import { useUser } from "../../context/UserContext";
import { Seating } from "../TableSeat/Seating";
import CartDropdown from "../CartPage/CartDropdown";
import logo from '../../Images/cafeImages/logo.jpeg';


interface NavbarProps {
  selectedSeat: string | null;
}

const ResponsiveNavbar: React.FC<NavbarProps> = ({ selectedSeat }) => {
  const { cart } = useCart();
  const {
    user: { userName, access },
  } = useUser();
  const [isSeatingDropdownOpen, setIsSeatingDropdownOpen] = useState(false);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const [isExpensesDropdownOpen, setIsExpensesDropdownOpen] = useState(false);

  const navigate = useNavigate();

  // Calculate the total number of items in the cart
  const itemCount = cart.reduce((total, item) => total + item.quantity, 0);

  // const toggleSeatingDropdown = () => {
  //   setIsSeatingDropdownOpen(!isSeatingDropdownOpen);
  //   setIsExpensesDropdownOpen(false);
  //   if (isAdminDropdownOpen) setIsAdminDropdownOpen(false);
  // };

  // const handleSeatSelected = (seatName: string) => {
  //   toggleSeatingDropdown(); // Toggle dropdown close when a seat is selected
  // };

  const handleCartDropdown = (d: boolean) => {
    setIsCartDropdownOpen(d);
    navigate("/cart");
  };

  // const toggleExpensesDropdown = () => {
  //   setIsExpensesDropdownOpen(!isExpensesDropdownOpen);
  //   setIsSeatingDropdownOpen(false);
  //   setIsAdminDropdownOpen(false);
  // };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: '#eedaba' }}
    >
      <Navbar.Brand as={NavLink} to="/home" style={{ color: '', fontWeight: 'bold', fontFamily: 'cursive, sans-serif', fontSize: 20 }}>
        <img
          src={logo}
          alt="Laxmi Mithai Logo"
          style={{ height: '50px', marginRight: '10px' }}
        />
        Laxmi Mithai
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {userName && (
            <>
              <Nav.Link as={NavLink} to="/home">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/search">
                Menu
              </Nav.Link>
              <Nav.Link as={NavLink} to="/menu">
                Dashboard
              </Nav.Link>
              <Nav.Link as={NavLink} to="/seating">
                Seating
              </Nav.Link>


            </>
          )}
          {access && (
            <>
              <NavDropdown title="Admin" id="collasible-nav-dropdown" style={{ color: '#ffffff' }}>
                <NavDropdown.Item as={NavLink} to="/completeOrders">
                  Complete Orders
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/pendingOrders">
                  Pending Orders
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/updateFood">
                  Update Food
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Expenses" id="collasible-nav-dropdown" style={{ color: '#ffffff' }}>
                <NavDropdown.Item as={NavLink} to="/expenses">
                  Expense History
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/save-expenses">
                  Add Expense
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
          <>
          </>
        </Nav>

        <Nav>
          <div
            onMouseEnter={() => setIsCartDropdownOpen(true)}
            onMouseLeave={() => setIsCartDropdownOpen(false)}
            style={{ position: "relative" }}
          >
            {userName && (
              <NavLink to="/cart" className="nav-link" style={{ color: '', marginRight: '1rem' }}>
                <FaShoppingCart />
                {" Cart"}
                {itemCount > 0 && (
                  <span className="badge bg-success ms-1">{itemCount}</span>
                )}
              </NavLink>
            )}
            {isCartDropdownOpen && (
              <CartDropdown setCartDropdown={handleCartDropdown} />
            )}
          </div>

          {(
            <Nav.Link as={NavLink} to="/login" style={{ color: '' }}>
              Sign In
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ResponsiveNavbar;

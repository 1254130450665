import React, { useEffect, useState } from "react";
import MessageModal from "../Utils/MessageModal";
import { SpinnerLoading } from "../Utils/SpinnerLoading";

interface SeatingComponentProps {
  seats: string[];
  error: string;
  loading: boolean;
  selectSeat: (seat: string) => void;
}

export const Seating: React.FC<SeatingComponentProps> = ({
  seats,
  error,
  loading,
  selectSeat,
}) => {
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

   // Categorize seats
   const tables = seats.filter(seat => seat.startsWith('Table'));
   const counters = seats.filter(seat => seat.startsWith('Counter'));
   const preOrders = seats.filter(seat => seat.startsWith('Pre Order'));

  const handleSeatChange = (seatName: string) => {
    selectSeat(seatName);
  };

  if (loading) return <SpinnerLoading />;
  if (error)
    return <MessageModal show={true} onClose={() => {}} message={error} />;

   // Render function for seats within each category
   const renderSeats = (seatArray: string[], title: string) => (
    <div className="col-md-4" style={{ backgroundColor: '#f2f2f2' }}>
       <div style={{
        backgroundColor: '#eedaba',
        display: 'flex',          // Enables flexbox
        justifyContent: 'center', // Centers content horizontally
        alignItems: 'center',     // Centers content vertically
        zIndex: 1050, // Use a high value here to ensure it's on top of other content
        fontFamily: 'Montserrat, sans-serif', // Google Font
        fontWeight: '700', // Bold
        color: '#333', // Dark grey for better readability
        padding: '20px 10px',  // Increased padding
        marginBottom: '20px',   // Added margin below the banner
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)' // Optional: Adds a subtle shadow to make the div stand out
      }}>
        <h3>{title}</h3>
      </div>
      
      {seatArray.map((seat) => (
        <div key={seat} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{seat}</h5>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handleSeatChange(seat)}
            >
              Select Seat
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="row">
        {renderSeats(tables, 'Table Orders')}
        {renderSeats(counters, 'Counter Orders')}
        {renderSeats(preOrders, 'Pre Orders')}
      </div>
    </div>
  );
};
import { useCallback, useState } from "react";
import { OrderService } from "../api/OrderService";
import {
  ApiResponseState,
  CreatedOrdersByDateResponse,
  CreatedOrdersInfo,
  OrdersInfo,
} from "../../models/ResponseModels";
import { useNavigate } from "react-router-dom";
import { useSeatViewModel } from "./SeatViewModel";
import { useOrderContext } from "../../context/OrderContext";

export const useCreatedOrderHistoryViewModel = () => {
  const navigate = useNavigate();

  const { selectSeat } = useSeatViewModel();
  const { setSaveOrderId } = useOrderContext();

  const [createdOrderRes, setCreatedOrderRes] = useState<
    ApiResponseState<CreatedOrdersInfo[]>
  >({
    loading: false,
    error: "",
    res: null,
  });

  const loadCreatedOrdersByDate = useCallback(
    async (date: string) => {
      setCreatedOrderRes((prev) => ({
        ...prev,
        loading: true,
      }));

      try {
        const response =
          await OrderService.createdOrdersByDate<CreatedOrdersByDateResponse>(
            date
          );
        if (response.status === "success" && response.data) {
          setCreatedOrderRes((prev) => ({
            ...prev,
            loading: false,
            res: response.data.payload,
          }));
        } else if (response.status === "error") {
          setCreatedOrderRes((prev) => ({
            ...prev,
            loading: false,
            error: response.error || "Failed to fetch foods",
          }));
        }
      } catch (error: any) {
        setCreatedOrderRes((prev) => ({
          ...prev,
          loading: false,
          error: error.message || "An error occurred while creating the order",
        }));
      }
    },
    [createdOrderRes]
  );

  const onCloseModel = useCallback(() => {
    navigate(`/home`);
  }, [navigate]);

  const handleAddOrderAction = useCallback(
    (seatName: string) => {
      selectSeat(seatName);
    },
    [selectSeat]
  );

  const handleCompleteOrderAction = useCallback(
    (orderId: number, seatName: string) => {
      setSaveOrderId(orderId);
      selectSeat(seatName);
      navigate(`/cart`);
    },
    [navigate]
  );

  return {
    createdOrderRes,
    onCloseModel,
    loadCreatedOrdersByDate,
    handleAddOrderAction,
    handleCompleteOrderAction,
  };
};

import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import OrderCard from "../common/OrderCard";
import { ApiResponseState, OrdersInfo } from "../../models/ResponseModels";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { isNotNullOrEmpty } from "../../util/DataHelpers";
import MessageModal from "../Utils/MessageModal";
import DatePicker from "react-datepicker";

interface OrderHistoryComponentProps {
  completeOrderRes: ApiResponseState<OrdersInfo[]>;
  loadCompletedOrdersByDate: (seat: string) => void;
  onCloseModel: () => void;
}

export const OrderHistory: React.FC<OrderHistoryComponentProps> = ({
  completeOrderRes,
  onCloseModel,
  loadCompletedOrdersByDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorMessage, setErrMessage] = useState("");

  useEffect(() => {
    loadCompletedOrdersByDate("");
  }, []);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      loadCompletedOrdersByDate(date.toISOString().split("T")[0]);
    }
  };

  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  //do nothing
  const handleAddOrderAction = (seatName: string) => {};

  const onCloseModalAction = () => {
    setShowDatePicker(true);
    setShowMessageModal(false);
    setErrMessage("");
  };

  //do nothing
  const handleCompleteOrderAction = (
    checkoutId: number,
    seatName: string
  ) => {};

  const handleBackGroundColor = () => {};

  useEffect(() => {
    if (isNotNullOrEmpty(completeOrderRes.error)) {
      setShowMessageModal(true);
      setErrMessage(completeOrderRes.error || "Error occurred");
    } else if (!completeOrderRes.res || completeOrderRes.res.length === 0) {
      setShowMessageModal(true);
      setErrMessage("No Order Found. Please Select Another Date");
    } else if (completeOrderRes) {
      setShowMessageModal(false);
    }
  }, [completeOrderRes]);

  if (completeOrderRes.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading />
      </div>
    );
  }

  let totalAmount = 0;
  if (completeOrderRes.res != null) {
    totalAmount = completeOrderRes.res!!.reduce(
      (acc, order) => acc + order.totalAmount,
      0
    );
  }

  return (
    <>
      <Container className="text-center my-3">
        {showDatePicker && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
          />
        )}
        <Button
          variant="btn btn-secondary"
          onClick={toggleDatePicker}
          className="ml-2"
        >
          {showDatePicker ? "Hide Calendar" : "Show Calendar"}
        </Button>
      </Container>
      <div className="d-flex justify-content-between flex-wrap mb-3">
        <div className="total-box p-2">
          <h3 className="m-0">
            Total:{" "}
            <span className="font-weight-bold">{totalAmount.toFixed(2)}</span>
          </h3>
        </div>
      </div>
      <MessageModal
        show={showMessageModal}
        onClose={onCloseModalAction}
        message={errorMessage}
      />
      {completeOrderRes.res && completeOrderRes.res.length > 0 && (
        <Row>
          {completeOrderRes.res?.map((order: OrdersInfo) => (
            <Col
              key={order.eventTimeStamp.createdTime}
              sm={12}
              md={6}
              lg={4}
              xl={3}
            >
              <OrderCard
                details={order}
                handleAddOrder={handleAddOrderAction}
                handleCompleteOrder={handleCompleteOrderAction}
                shouldShowDate={true}
                cardBackgroundColor={"#a1a1a1"}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

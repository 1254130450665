import { useState } from "react";
import Input from "./form/Input";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useUser } from "../../context/UserContext";

import {
    Card,
    Button,
    ListGroup,
    Spinner,
    Alert,
    Form,
    ButtonGroup,
} from "react-bootstrap";

const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

export const Login = () => {
    const [username, setUsername] = useState("");
   // const [password, setPassword] = useState("");
    const { setUser } = useUser();

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        //build the request payload
    let payload = {
        username: username,
       // password: password,
      }
  
      const requestOptions: RequestInit = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
  
      try {
        const response = await fetch(`${APP_API_URL}/authenticate`, requestOptions);
        const data = await response.json();
        if (response.ok) {
            // Assuming the response includes the user object and access status
            setUser({ userName: data.user.username, access: data.user.access });
            navigate(`/home`)
        } else {
            // Handle errors, e.g., invalid credentials
            console.error('Login failed:', data.message);
        }
    } catch (error) {
        console.error('Failed to login', error);
        // Implement additional error handling if necessary
    }

    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10">
                    <h2>Login to your account</h2>
                    <hr />

                    <form onSubmit={handleSubmit}>
                        <Input
                            title="Username"
                            type="username"
                            className="form-control"
                            name="username"
                            autoComplete="username-new"
                            onChange={(loginEvent: React.ChangeEvent<HTMLInputElement>) =>
                                setUsername(loginEvent.target.value)
                            }
                        />

                        {/* <Input
                            title="Password"
                            type="password"
                            className="form-control"
                            name="password"
                            autoComplete="password-new"
                            onChange={(loginEvent: React.ChangeEvent<HTMLInputElement>) =>
                                setPassword(loginEvent.target.value)
                            }
                        /> */}

                        <hr />

                        <div className="d-flex justify-content-center mt-3">
                            <Button
                                className="btn text-white"
                                style={{ backgroundColor: "#6c757d" }}
                                variant="primary"
                                type="submit"
                                value="Login"
                            >
                                Login
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
import { useEffect, useState } from "react";
import UpdateFood from "../../components/updateFood/UpdateFood";
import { ApiResponseState, Food } from "../../models/ResponseModels";
import { useFoodViewModel } from "../viewModel/FoodViewModel";
import { isNotNullOrEmpty, isNullOrEmpty } from "../../util/DataHelpers";

export const UpdateFoodViewController = () => {
  const [loadFoodRes, setLoadFoodRes] = useState<ApiResponseState<Food[]>>({
    loading: false,
    error: "",
    res: null,
  });

  const { loading, error, foods, saveUpdatedFood, updateFoodRes, loadFoods, addNewFoodRes, addNewFood } =
    useFoodViewModel();

  useEffect(() => {
    if (loading) {
      setLoadFoodRes({ loading: true, error: "", res: null });
    } else if (error) {
      setLoadFoodRes({
        loading: false,
        error: error || "Failed to fetch foods",
        res: null,
      });
    } else if (foods) {
      setLoadFoodRes({ loading: false, error: "", res: foods });
    }
  }, [loading, error, foods]); // Only re-run the effect if these values change

  return (
    <UpdateFood
      onSearchFoodClick={(searchValue, action) => {
        loadFoods(searchValue, action);
      }}
      loadFoodRes={loadFoodRes}
      handleSave={saveUpdatedFood}
      updateFoodRes={updateFoodRes}
      addNewFoodRes={addNewFoodRes}
      addNewFood={addNewFood}
    />
  );
};

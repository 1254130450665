import React, { createContext, useContext, useState, ReactNode } from 'react';

interface User {
  userName: string ;
  access: boolean;
}

interface UserStateContextType {
  user: User ;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const defaultUserState: User = {
  userName: "",
  access: false,
};

export const UserContext = createContext<UserStateContextType | undefined >(
  undefined
);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] =  useState<User >(defaultUserState);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
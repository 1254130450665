import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

interface DiscountModalProps {
  show: boolean;
  onHide: () => void;
  onDiscountSubmit: (discount: number) => void;
}

export const DiscountModal: React.FC<DiscountModalProps> = ({
  show,
  onHide,
  onDiscountSubmit,
}) => {
  const [discountInput, setDiscountInput] = useState(0);

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountInput(parseFloat(e.target.value));
  };

  const handleOk = () => {
    onDiscountSubmit(discountInput);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Discount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="number"
          className="form-control"
          placeholder="Enter discount amount"
          onChange={handleDiscountChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleOk} style={{ backgroundColor: "#a27e57" }}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

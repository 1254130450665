import { CreatedOrderHistory } from "../../components/OrderPage/CreatedOrders";
import { useCreatedOrderHistoryViewModel } from "../viewModel/CreatedOrderHistoryViewModel";

export const CreatedOrderViewViewController = () => {
  console.log("SeatViewController rendering"); // Check if this logs

  const {
    createdOrderRes,
    loadCreatedOrdersByDate,
    onCloseModel,
    handleCompleteOrderAction,
    handleAddOrderAction,
  } = useCreatedOrderHistoryViewModel();

  return (
    <CreatedOrderHistory
      createdOrderRes={createdOrderRes}
      onCloseModel={onCloseModel}
      loadCreatedOrdersByDate={loadCreatedOrdersByDate}
      handleCompleteOrderAction={handleCompleteOrderAction}
      handleAddOrderAction={handleAddOrderAction}
    />
  );
};

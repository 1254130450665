import React from 'react';
import logo2 from '../../Images/cafeImages/logo33.jpeg';
import mithaicombo from '../../Images/cafeImages/mithaicombo.jpg';
import mithaiImagek from '../../Images/cafeImages/mithai.jpg';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage: React.FC = () => {
    return (
        <div className="container-fluid p-0">
            <Carousel>
                <Carousel.Item>
                    <img className="d-block w-100" src={mithaiImagek} alt="First slide" />
                    <Carousel.Caption style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Light background with some transparency
                        padding: '10px',
                        borderRadius: '10px',
                    }}>
                        <h3 style={{ fontFamily: "'Playfair Display', cursive", color: '#333' }}>Welcome to Laxmi Mithai</h3>
                        <p style={{ fontFamily: "'Playfair Display', cursive", color: '#333' }}>Enjoy the finest sweets and snacks at your favorite café.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={mithaicombo} alt="Second slide" />
                    <Carousel.Caption style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Light background with some transparency
                        padding: '4px',
                        borderRadius: '10px',
                    }}>
                        <h3 style={{ fontFamily: "'Playfair Display', cursive", color: '#333' }}>Laxmi Mithai</h3>
                        
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div>
                <div className="d-none d-lg-block">
                    <div className="row g-0 mt-3"> {/* Adjust margin-top to reduce gap */}
                        <div className="col-sm-6 col-md-6">
                            <div className="col-image-left">
                                <img className="d-block w-100" src={logo2} alt="Second slide" />
                            </div>                       
                        </div>
                        <div className="col-4 col-md-4 container d-flex justify-content-center align-items-center">
                            <div className="ml-2">
                                <h1>About us</h1>
                                <p className="lead">
                                    Welcome to Laxmi Mithai, a haven for sweet lovers and culinary enthusiasts located in the heart of Suryabinayak, Bhaktapur,
                                    our shop is dedicated to crafting the finest traditional sweets and snacks that capture the essence of our rich cultural heritage.
                                    Each delicacy is made with love, using time-honored recipes and the highest quality ingredients.
                                    Whether it's our melt-in-your-mouth ladoos, crispy jalebis, or savory samosas, we promise a delightful experience with every bite.

                                    At Laxmi Mithai, we believe in celebrating the joyous moments of life with flavors that evoke nostalgia and warmth.
                                    Our mission is to bring people together through the universal love of sweets, providing a taste of tradition in every treat.
                                    Come visit us and indulge in the sweet symphony of flavors that define our beloved mithai shop.
                                </p>
                                <a className="btn btn-secondary" href="http://localhost:3000/login">
                                    Login
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mt-3"> {/* Adjust margin-top to reduce gap */}
                        <div className="col-4 col-md-4 container d-flex justify-content-center align-items-center">
                            <div className="ml-2">
                                <h1>Our menu is always evolving!</h1>
                                <p className="lead">
                                    Our Specialties
                                </p>
                                <p>Try our exclusive range of traditional sweets and fresh snacks made daily with love.</p>
                                <button className="btn btn-secondary">View Menu</button>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="col-image-right">
                                <img className="d-block w-100" src={mithaiImagek} alt="Second slide" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile Heros */}
                <div className="d-lg-none">
                <div className="col-sm-6 col-md-6">
                            <div className="col-image-left">
                                <img className="d-block w-100" src={logo2} alt="Second slide" />
                            </div>
                        </div>
                    <div className="container">
                        <div className="m-2">
                            <div className="col-image-left"></div>
                            <div className="mt-2">
                                <h1>About us</h1>
                                <p className="lead">
                                    Welcome to Laxmi Mithai, a haven for sweet lovers and culinary enthusiasts located in the heart of Suryabinayak, Bhaktapur,
                                    our shop is dedicated to crafting the finest traditional sweets and snacks that capture the essence of our rich cultural heritage.
                                    Each delicacy is made with love, using time-honored recipes and the highest quality ingredients.
                                    Whether it's our melt-in-your-mouth ladoos, crispy jalebis, or savory samosas, we promise a delightful experience with every bite.

                                    At Laxmi Mithai, we believe in celebrating the joyous moments of life with flavors that evoke nostalgia and warmth.
                                    Our mission is to bring people together through the universal love of sweets, providing a taste of tradition in every treat.
                                    Come visit us and indulge in the sweet symphony of flavors that define our beloved mithai shop.
                                </p>
                                <a className="btn btn-secondary" href="http://localhost:3000/login">
                                    Login
                                </a>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="col-image-right"></div>
                            <div className="mt-2">
                                <h1>Our menu is always evolving!</h1>
                                <p className="lead">
                                    Our Specialties
                                </p>
                                <p>Try our exclusive range of traditional sweets and fresh snacks made daily with love.</p>
                                <button className="btn btn-secondary">View Menu</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;

import { APIErrorType, GenericDateResultViewState } from "./ApiResult";

export async function handleResponse<T>(
  response: Response | Error
): Promise<GenericDateResultViewState<T>> {
  console.log(
    "response instance",
    response instanceof Error ? "Error" : "Response"
  );

  if (response instanceof Error) {
    console.error("Failed to process response", response);
    if (response.message.includes("Failed to fetch")) {
      return {
        status: "error",
        error:
          "Unable to connect to the server. Please check your network connection.",
      };
    } else {
      return {
        status: "error",
        error: "An unexpected error occurred",
      };
    }
  } else {
    console.log("!response.ok check", response.ok);
    if (!response.ok) {
      console.log("error ", response.status);
      const errorType =
        response.status === 404
          ? APIErrorType.NOTFOUND
          : response.status >= 500
          ? APIErrorType.NETWORK
          : APIErrorType.GENERAL;

      return {
        status: "error",
        error: `HTTP error! Status: ${response.status}, Type: ${errorType}`,
      };
    }
    console.log("response received : ", response);
    try {
      const data: T = await response.json();
      if (isPayloadEmpty(data)) {
        return {
          status: "error",
          error: "No meaningful data available or logical error",
        };
      } else {
        return { status: "success", data: data };
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {
        status: "error",
        error: "Error parsing JSON",
      };
    }
  }
}

function isPayloadEmpty(payload: any): boolean {
  if (payload === null || payload === undefined) return true;
  if (Array.isArray(payload)) return payload.length === 0;
  if (typeof payload === "object") {
    return (
      Object.keys(payload).length === 0 ||
      Object.values(payload).every((item) => isPayloadEmpty(item))
    );
  }
  return false;
}

import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageModal from "../Utils/MessageModal";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import AutoCloseMessageModal from "../Utils/AutoCloseMessageModal";
import ExpensesReviewModal from "../Utils/ExpensesReviewModal";

interface SaveExpenseComponentProps {
  saveExpenses: (
    description: string,
    amount: number,
    quantity: number,
    date: string
  ) => void;
  error: string;
  loading: boolean;
  createExpensesRes: string;
  onCloseModel: () => void;
  shouldDisplayModelFlag: () => boolean;
}

const SaveExpense: React.FC<SaveExpenseComponentProps> = ({
  saveExpenses,
  error,
  loading,
  createExpensesRes,
  onCloseModel,
  shouldDisplayModelFlag,
}) => {
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleSubmit = () => {
    saveExpenses(
      description,
      amount,
      quantity,
      selectedDate.toISOString().split("T")[0]
    );
    setShowReviewModal(false);
  };

  const shouldDisplayModel = useCallback(() => {
    setShowModal(shouldDisplayModelFlag);
  }, [showModal]);

  const handleOnCloseModel = () => {
    console.log("Modal is being closed");
    setShowModal(false);
    onCloseModel();
  };

  const handleReview = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowReviewModal(true);
  };

  if (loading) return <SpinnerLoading />;
  if (error) {
    return (
      <MessageModal
        show={showModal}
        onClose={handleOnCloseModel}
        message={error}
      />
    );
  }

  console.log("createExpensesRes", createExpensesRes);

  if (createExpensesRes) {
    return (
      <AutoCloseMessageModal
        showInitially={true}
        redirectPath="/expenses"
        message={`${createExpensesRes}`}
      />
    );
  }

  return (
    <div className="container" style={{ maxWidth: "600px", marginTop: "20px" }}>
      <h2 className="text-center mb-4" style={{ color: "brown" }}>
        Add Expense
      </h2>
      <form onSubmit={handleReview} style={{ color: "brown" }}>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            placeholder="Enter description"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount
          </label>
          <input
            type="number"
            className="form-control"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            required
            placeholder="Enter amount"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="quantity" className="form-label">
            Quantity
          </label>
          <input
            type="number"
            className="form-control"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            required
            placeholder="Enter quantity"
          />
        </div>
        <div className="mb-3 d-flex flex-column">
          <label htmlFor="date" className="form-label">
            Date
          </label>
          <div>
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date) => setSelectedDate(date)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          style={{ backgroundColor: "#6c4c41", borderColor: "#6c4c41" }}
        >
          Review Expense
        </button>
      </form>
      <ExpensesReviewModal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        onSave={handleSubmit}
        expenseData={{
          description,
          amount,
          quantity,
          date: selectedDate.toISOString().split("T")[0],
        }}
      />
    </div>
  );
};

export default SaveExpense;

import { APIErrorType } from "../../util/ApiResult";
import { handleResponse } from "../../util/ResponseHandler";

const APP_API_URL = process.env.REACT_APP_API_BASE_URL;

export class SeatService {
  static async getAllSeats<T>() {
    try {
      const response = await fetch(`${APP_API_URL}/seats`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return handleResponse<T>(response);
    } catch (error: any) {
      return handleResponse<T>(new Error(error.msg));
    }
  }
}

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import { CartItem } from "../models/ResponseModels";
import { useOrderViewModel } from "../model/viewModel/OrderViewModel";
import { isValidNumber } from "../util/DataHelpers";

interface CartContextType {
  cart: CartItem[];
  addToCart: (food: CartItem) => void;
  handleQtyUpdateAction: (
    updateQty: number,
    itemName: string,
    itemId: number,
    price: number
  ) => void;
  totalAmount: number;
  clearCart: () => void;
  updateActionState: string;
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

// Default values
const CartContext = createContext<CartContextType>({
  cart: [],
  addToCart: () => {},
  handleQtyUpdateAction: () => {},
  totalAmount: 0,
  clearCart: () => {},
  updateActionState: "",
  setCart: () => {},
});

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [updateActionState, setUpdateActionState] = useState<string>("");

  const { updateOrder, saveOrderId } = useOrderViewModel();

  // Sets the cart to an empty array when we create order and response is success
  const clearCart = () => {
    setCart([]);
  };

  const addToCart = (food: CartItem) => {
    console.log("add to cart");
    setCart((prev) => {
      // Find existing items in the cart
      const existingQtyItem = prev.find(
        (item) => item.id === food.id && item.unitInKg === 0
      );
      const existingUnitInKgItem = prev.find(
        (item) => item.id === food.id && item.unitInKg !== 0
      );

      if (food.unitInKg !== undefined && food.unitInKg > 0) {
        // Case: Add by unitInKg
        if (existingUnitInKgItem) {
          // Update existing item with unitInKg
          return prev.map((item) =>
            item.id === food.id && item.unitInKg !== 0
              ? { ...item, unitInKg: item.unitInKg + food.unitInKg }
              : item
          );
        } else {
          // Add new entry for unitInKg
          return [
            ...prev,
            {
              ...food,
              quantity: 0,
            },
          ];
        }
      } else {
        // Case: Add by quantity
        if (existingQtyItem) {
          // Update existing item with quantity
          return prev.map((item) =>
            item.id === food.id && item.unitInKg === 0
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          // Add new entry for quantity
          return [
            ...prev,
            {
              ...food,
              quantity: 1,
              unitInKg: 0,
            },
          ];
        }
      }
    });
  };

  const handleQtyUpdateAction = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    setCart((prevCart) => {
      let newCart;
      let action = "none"; // Initialize action to "none"

      console.log(
        `Handling quantity update for item ${itemName}: ${updatedQty}`
      );

      if (updatedQty === 0) {
        // If updated quantity is zero, remove the item from the cart
        newCart = prevCart.filter((item) => {
          if (item.name !== itemName) {
            return true;
          } else {
            action = "delete";
            console.log(`Removing item: ${itemName} from cart`);
            return false;
          }
        });
      } else {
        // Update the quantity of the item
        newCart = prevCart.map((item) => {
          if (item.name === itemName) {
            action = "update";
            console.log(`Updating item: ${itemName} to quantity ${updatedQty}`);
            return { ...item, quantity: updatedQty };
          }
          return item;
        });
      }

      console.log("update action before calling update api ", action);
      // Log and set the action state only if action is not "none"
      if (action !== "none") {
        console.log(`Action performed: ${action}`);
        setUpdateActionState(action);

        // Log the state before updating
        console.log(`Cart before update:`, prevCart);
        console.log(`Cart after update:`, newCart);

        // Call updateOrder only if an action has occurred
        console.log(`Cart update saveOrderId:`, saveOrderId);
      }

      return newCart;
    });
  };

  const totalAmount = useMemo(() => {
    return cart.reduce((total, item) => {
      if (item.unitInKg > 0) {
        total += item.unitInKg * item.priceInKg;
      }
      if (item.quantity > 0) {
        total += item.price * item.quantity;
      }
      return total;
    }, 0);
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        handleQtyUpdateAction,
        totalAmount,
        clearCart,
        updateActionState,
        setCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Hook to use the cart context
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa"; // FontAwesome icons for a nicer touch
import {
  CartItem,
  PaymentInfo,
  ResExistingOrderBySeatState,
  ResponseCompleteState,
  ResponseUpdateItemState,
} from "../../models/ResponseModels";
import { DiscountModal } from "../Utils/DiscountModal";
import { ModalConfirmation } from "../Utils/ModalConfirmation";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import MessageModal from "../Utils/MessageModal";
import AutoCloseMessageModal from "../Utils/AutoCloseMessageModal";
import CartItemComponent from "../common/CartItem";

interface CartPageComponentProps {
  saveOrderId: number;
  cart: CartItem[];
  seatName: string;
  loading: boolean;
  error: string;
  totalAmount: number;
  completeOrderState: ResponseCompleteState;
  handleQtyUpdateAction: (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number
  ) => void;
  handleCompleteOrder: (
    paymentInfo: PaymentInfo,
    seatName: string,
    orderId: number
  ) => void;
  onCloseModel: () => void;
  shouldDisplayModelFlag: () => boolean;
  existingOrdersBySeatRes: ResExistingOrderBySeatState;
  updateOrderState: ResponseUpdateItemState;
}

const CartPage: React.FC<CartPageComponentProps> = ({
  saveOrderId,
  cart,
  seatName,
  error,
  loading,
  totalAmount,
  handleQtyUpdateAction,
  handleCompleteOrder,
  completeOrderState,
  onCloseModel,
  shouldDisplayModelFlag,
  existingOrdersBySeatRes,
  updateOrderState,
}) => {
  console.log("loading cart page");

  console.log("Cart page updateOrderState : ", updateOrderState);

  if (updateOrderState.response) {
    console.log("updated res ", updateOrderState.response);
  }

  console.log("Cart page existingOrdersBySeatRes : ", existingOrdersBySeatRes);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [showModel, setShowModel] = useState<boolean>(true);

  const incrementCount = (
    count: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    handleQtyUpdateAction(count + 1, itemName, itemId, price);
  };

  const decrementCount = (
    count: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    if (count > 0) {
      handleQtyUpdateAction(count - 1, itemName, itemId, price);
    }
  };

  const shouldDisplayModel = useCallback(() => {
    setShowModel(shouldDisplayModelFlag);
  }, [showModel]);

  // Memoize the subtotal and total calculation to avoid unnecessary recalculations
  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      let itemSubtotal = 0;
      if (item.unitInKg > 0) {
        itemSubtotal += item.unitInKg * item.priceInKg;
      }
      if (item.quantity > 0) {
        itemSubtotal += item.price * item.quantity;
      }
      return acc + itemSubtotal;
    }, 0);
  }, [cart]);

  const total = useMemo(() => {
    return subtotal - discount;
  }, [subtotal, discount]);

  const handleApplyDiscount = (discountValue: number) => {
    setDiscount(discountValue);
    setIsDiscountModalOpen(false);
  };

  const handleConfirmNo = () => {
    setShowConfirmPopup(false);
  };

  const openDiscountModal = () => {
    setIsDiscountModalOpen(true);
  };

  const closeDiscountModal = () => {
    setIsDiscountModalOpen(false);
  };

  const handleCompleteOrderButtonAction = () => {
    setShowConfirmPopup(true);
  };

  const handleConfirmYes = (paymentType: string) => {
    if (saveOrderId === 0) {
      console.log("order Id invalid");
      return;
    }
    const paymentInfo = {
      paymentType,
      debitAmount: total,
      creditAmount: 0,
      discountAmount: discount,
      totalAmount: total,
    };
    handleCompleteOrder(paymentInfo, seatName, saveOrderId);
    setShowConfirmPopup(false);
  };

  // Conditional renderings for loading, error, and response states
  if (
    existingOrdersBySeatRes.loading ||
    loading ||
    completeOrderState.loading
  ) {
    console.log(
      "existingOrdersBySeatRes.loading : ",
      existingOrdersBySeatRes.loading
    );
    console.log("completeOrderState.loading : ", completeOrderState.loading);
    console.log("loading : ", loading);
    return <SpinnerLoading />;
  }

  if (completeOrderState.error || existingOrdersBySeatRes.error)
    return (
      <MessageModal
        show={showModel}
        onClose={onCloseModel}
        message={completeOrderState.error!! || existingOrdersBySeatRes.error!!}
      />
    );
  if (completeOrderState.response)
    return (
      <AutoCloseMessageModal
        showInitially={true}
        redirectPath="/home"
        message={completeOrderState.response!!}
      />
    );
  if (cart.length === 0)
    return (
      <AutoCloseMessageModal
        showInitially={true}
        redirectPath="/home"
        message="Cart is Empty"
      />
    );

  return (
    <div className="container mt-4">
      <h2>
        Your Cart: Order #{saveOrderId} - {seatName}
      </h2>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item) => (
            <CartItemComponent
              key={item.id}
              item={item}
              onIncrement={incrementCount}
              onDecrement={decrementCount}
              loading={updateOrderState.loading!!}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>Subtotal</th>
            <th>${subtotal.toFixed(2)}</th>
          </tr>
          <tr>
            <th colSpan={3}>Discount</th>
            <th>${discount.toFixed(2)}</th>
          </tr>
          <tr>
            <th colSpan={3}>Total</th>
            <th>${total.toFixed(2)}</th>
          </tr>
        </tfoot>
      </table>
      <button
        className="btn text-white"
        style={{ backgroundColor: "#6c757d" }}
        onClick={handleCompleteOrderButtonAction}
      >
        Complete Order
      </button>
      <button
        className="btn text-white"
        style={{ backgroundColor: "#a27e57" }}
        onClick={openDiscountModal}
      >
        Add Discount
      </button>
      <DiscountModal
        show={isDiscountModalOpen}
        onHide={closeDiscountModal}
        onDiscountSubmit={handleApplyDiscount}
      />
      <ModalConfirmation
        show={showConfirmPopup}
        message="Are you sure you want to complete the order? Select a payment method:"
        onClose={handleConfirmNo}
        onConfirm={handleConfirmYes}
      />
    </div>
  );
};

export default CartPage;

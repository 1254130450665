import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ExpensesReviewModalProps {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  expenseData: {
    description: string;
    amount: number;
    quantity: number;
    date: string;
  };
}

const ExpensesReviewModal: React.FC<ExpensesReviewModalProps> = ({
  show,
  onHide,
  onSave,
  expenseData,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton style={{ backgroundColor: "#9DA7AC" }}>
        <Modal.Title>Review Expense</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#A2A8AB" }}>
        <p>
          <strong>Description:</strong> {expenseData.description}
        </p>
        <p>
          <strong>Amount:</strong> {expenseData.amount}
        </p>
        <p>
          <strong>Quantity:</strong> {expenseData.quantity}
        </p>
        <p>
          <strong>Date:</strong> {expenseData.date}
        </p>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#494C4E" }}>
        <Button variant="btn btn-danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="btn btn-dark" onClick={onSave}>
          Save Expense
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpensesReviewModal;

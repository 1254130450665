import React, { useState } from "react";
import { Food } from "../../models/ResponseModels";
import dhudbari from "../../Images/cafeImages/dhudbari.jpg";
import ByPieceOrUnitModal from "../common/ByPieceOrUnitModal";
import AddFoodByKgModal from "../common/AddFoodByKgModal";

interface FoodCardProps {
  food: Food;
  addToCart: (item: Food) => void;
  imageHeight: string;
}

const FoodCard: React.FC<FoodCardProps> = ({
  food,
  addToCart,
  imageHeight = "120px",
}) => {
  const [showKgModel, setShowKgModel] = useState<boolean>(false);
  const [showByPieceOrUnitModal, setShowByPieceOrUnitModal] =
    useState<boolean>(false);

  const handleShow = () => setShowKgModel(true);
  const handleClose = () => setShowKgModel(false);

  const handleShowByPieceOrUnitModal = () => setShowByPieceOrUnitModal(true);
  const handleCloseByPieceOrUnitModal = () => setShowByPieceOrUnitModal(false);

  const addToCartAction = (item: Food) => {
    if (item.priceInKg > 0 || item.priceKgTwo > 0) {
      handleShowByPieceOrUnitModal();
    } else {
      addToCart(food);
    }
  };

  const handleSave = (updatedFood: Food) => {
    handleClose();
    addToCart(updatedFood);
  };

  const handleAddByUnit = () => {
    handleCloseByPieceOrUnitModal();
    handleShow();
  };

  const handleAddByPiece = () => {
    handleCloseByPieceOrUnitModal();
    addToCart(food);
  };

  return (
    <div className="col-md-3 mb-4">
      <div className="card h-100 shadow-sm">
        <img
          src={food.img || dhudbari}
          className="card-img-top"
          style={{ height: imageHeight, objectFit: "cover", width: "100%" }}
          alt={food.name}
        />
        <div className="card-body d-flex flex-column">
          <h5
            className="card-title"
            style={{ fontFamily: "Serif, sans-serif", fontSize: 16 }}
          >
            {" "}
            {food.name}
          </h5>

          <div className="mt-auto">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="text-success font-weight-bold">
                <strong>Nrs.{food.price}</strong>
              </span>
            </div>
            <button
              className="btn btn-secondary w-100 mt-2"
              onClick={() => addToCartAction(food)}
              style={{ backgroundColor: "#6c757d" }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <ByPieceOrUnitModal
        show={showByPieceOrUnitModal}
        handleClose={handleCloseByPieceOrUnitModal}
        handleAddByUnit={handleAddByUnit}
        handleAddByPiece={handleAddByPiece}
      />
      <AddFoodByKgModal
        show={showKgModel}
        handleClose={handleClose}
        food={food}
        handleSave={handleSave}
      />
    </div>
  );
};

export default FoodCard;

export enum APIErrorType {
  GENERAL = "GENERAL",
  NETWORK = "NETWORK",
  NOTFOUND = "NOTFOUND",
  CONNECTION = "CONNECTION_REFUSED",
}

export type GenericDateResultViewState<T> =
  | { status: "idle" }
  | { status: "loading" }
  | { status: "success"; data: T }
  | { status: "error"; error: string };

import React, { useState, useEffect } from "react";
import { Container, Button, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import MessageModal from "../Utils/MessageModal";
import { ExpensesDetail } from "../../models/ResponseModels";
import { isNotNullOrEmpty } from "../../util/DataHelpers";

interface ExpenseComponentProps {
  expensesRes?: ExpensesDetail[];
  error: string;
  loading: boolean;
  loadGetExpensesByDateRange: (date: string) => void;
  clearAutoCloseMessage: () => void;
}

const Expense: React.FC<ExpenseComponentProps> = ({
  expensesRes,
  error,
  loading,
  loadGetExpensesByDateRange,
  clearAutoCloseMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [errorMessage, setErrMessage] = useState("");

  clearAutoCloseMessage();

  useEffect(() => {
    if (isNotNullOrEmpty(error)) {
      setShowModal(true);
    }
  }, [error]); // Dependency on error to trigger modal display

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      loadGetExpensesByDateRange(date.toISOString().split("T")[0]);
    }
  };

  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  const totalExpenses = expensesRes!!.reduce(
    (total, expense) => total + expense.amount,
    0
  );

  const onCloseModalAction = () => {
    setShowDatePicker(true);
    setShowMessageModal(false);
    setErrMessage("");
  };

  useEffect(() => {
    if (isNotNullOrEmpty(error)) {
      setShowMessageModal(true);
      setErrMessage(error || "Error occurred");
    } else if (!expensesRes || expensesRes.length === 0) {
      setShowMessageModal(true);
      setErrMessage("No Order Found. Please Select Another Date");
    } else if (expensesRes) {
      setShowMessageModal(false);
    }
  }, [expensesRes]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading />
      </div>
    );
  }

  return (
    <>
      <Container className="text-center my-3">
        {showDatePicker && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
          />
        )}
        <Button variant="primary" onClick={toggleDatePicker} className="ml-2">
          {showDatePicker ? "Hide Calendar" : "Show Calendar"}
        </Button>
      </Container>
      {expensesRes && (
        <Container className="text-center my-3">
          <h3>Total Expenses: Nrs {totalExpenses.toFixed(2)}</h3>
        </Container>
      )}
      <MessageModal
        show={showMessageModal}
        onClose={onCloseModalAction}
        message={errorMessage}
      />
      {expensesRes!!.length > 0 && (
        <Container>
          <Table
            striped
            bordered
            hover
            className="mt-3"
            style={{ color: "brown" }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {expensesRes!!.map((expense) => (
                <tr key={expense.id}>
                  <td>{expense.created_at}</td>
                  <td>{expense.description}</td>
                  <td>Nrs. {expense.amount.toFixed(2)}</td>
                  <td>{expense.quantity}</td>
                  <td>Nrs.{expense.quantity * expense.amount}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
    </>
  );
};

export default Expense;

export const getPaginatedData = <T>(
  data: T[],
  currentPage: number,
  itemsPerPage: number
): T[] => {
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  return data.slice(firstItemIndex, lastItemIndex);
};

export const getTotalPages = (
  totalItems: number,
  itemsPerPage: number
): number => {
  return Math.ceil(totalItems / itemsPerPage);
};

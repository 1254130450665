import React, { useState } from "react";
import { ApiResponseState, Food } from "../../models/ResponseModels";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import MessageModal from "../Utils/MessageModal";
import { isNotNullOrEmpty } from "../../util/DataHelpers";
import { getPaginatedData, getTotalPages } from "../Utils/PaginationUtils";
import FoodItem from "./FoodItem";
import PaginationControls from "../common/PaginationControls";
import AddNewFoodModal from "../common/AddNewFoodModal";

interface FoodComponentProps {
  loadFoodRes: ApiResponseState<Food[]>;
  updateFoodRes: ApiResponseState<string>;
  addNewFoodRes: ApiResponseState<string>;
  onSearchFoodClick: (searchValue: string, action: string) => void;
  handleSave: (food: Food) => void;
  addNewFood: (food: Food) => void;
}

const UpdateFood: React.FC<FoodComponentProps> = ({
  loadFoodRes,
  onSearchFoodClick,
  handleSave,
  updateFoodRes,
  addNewFoodRes,
  addNewFood,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(true);
  const [showAddFoodModal, setShowAddFoodModal] = useState(false);
  const handleShowAddFoodModal = () => setShowAddFoodModal(true);
  const handleCloseAddFoodModal = () => setShowAddFoodModal(false);


  const itemsPerPage = 12;

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchTerm) {
      onSearchFoodClick(searchTerm, "NAME");
    } else {
      onSearchFoodClick("food", "ALL");
    }
  };

  console.log("food loadFoodRes : update page : ", loadFoodRes);
  console.log("food updateFoodRes : update page : ", updateFoodRes);
  console.log("food addNewFoodRes : update page : ", addNewFoodRes);

  const currentItems = getPaginatedData(
    loadFoodRes.res || [],
    currentPage,
    itemsPerPage
  );
  const totalPages = getTotalPages(loadFoodRes.res?.length || 0, itemsPerPage);

  if (loadFoodRes.loading || updateFoodRes.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <SpinnerLoading />
      </div>
    );
  }

  if (
    isNotNullOrEmpty(loadFoodRes.error) ||
    isNotNullOrEmpty(updateFoodRes.error)
  ) {
    return (
      <MessageModal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={loadFoodRes.error || updateFoodRes.error}
      />
    );
  }

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center my-3">
        {" "}
        {/* Added 'my-3' for margin-top and margin-bottom */}
        <Form onSubmit={handleSearch} className="d-flex flex-grow-1 me-2">
          <Form.Control
            type="text"
            placeholder="Search by food name"
            value={searchTerm}
            className="flex-grow-1 me-2"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ maxWidth: "200px" }} // Control the maximum width of the input box
          />
          <Button variant="dark" type="submit">
            Search
          </Button>
        </Form>
        <Button
          variant="secondary"
          onClick={handleShowAddFoodModal}
          style={{ backgroundColor: "#343a40" }}
        >
          {" "}
          Add Food
        </Button>
      </div>
      <Row>
        {currentItems.map((food) => (
          <FoodItem key={food.id} food={food} handleSave={handleSave} />
        ))}
      </Row>
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        goToNextPage={() => setCurrentPage(currentPage + 1)}
        goToPreviousPage={() => setCurrentPage(currentPage - 1)}
      />
        <AddNewFoodModal
        show={showAddFoodModal}
        handleClose={handleCloseAddFoodModal}
        handleAddNewFood={addNewFood}
      />
    </Container>
  );
};

export default UpdateFood;

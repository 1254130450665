/**
 * FoodViewController.tsx
 * The View component that renders the user interface for the food menu.
 * It utilizes the FoodViewModel to handle data and business logic,
 * passing user actions back to the ViewModel and subscribing to its observables to receive updates.
 * It is responsible for presenting the data on the UI and delegating user interactions back to the ViewModel.
 */

import { useCallback } from "react";
import SearchFood from "../../components/SearchFood/searchFood";
import { useCart } from "../../context/CartContext";
import { useOrderContext } from "../../context/OrderContext";
import {
  checkQuantityAction,
  isNotNullOrEmpty,
  isValidNumber,
} from "../../util/DataHelpers";
import { useFoodViewModel } from "../viewModel/FoodViewModel";
import { useOrderViewModel } from "../viewModel/OrderViewModel";
import { CartItem } from "../../models/ResponseModels";

export const FoodViewController = () => {
  console.log("FoodViewController rendering"); // Check if this logs

  const {
    foods,
    categories,
    error,
    loading,
    loadFoods,
    seatName,
    addToCart,
    cart,
    totalAmount,
    onCloseModel,
  } = useFoodViewModel();

  const {
    responseState,
    saveOrder,
    updateOrderState,
    existingBySeatNameState,
    updateOrder,
  } = useOrderViewModel();

  const { saveOrderId } = useOrderContext();

  const { updateActionState, handleQtyUpdateAction } = useCart();

  const setShouldDisplayModel = () => {
    console.log(
      "existingBySeatNameState.error!!",
      existingBySeatNameState.error!!
    );
    return (
      isNotNullOrEmpty(error) ||
      isNotNullOrEmpty(existingBySeatNameState.error!!)
    );
  };

  const updateActionHandler = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number,
    priceInKg: number,
    unitInKg: number
  ) => {
    console.log("cartVC saveOrderId : ", saveOrderId);

    // if saveOrderId means existing order is present we make
    // api call for presistance else would just update the local state in cart context
    if (isValidNumber(saveOrderId)) {
      console.log("cartVC valid saveOrderId : ", saveOrderId);

      console.log(
        "updateActionState success and calling updateOrder : ",
        updateActionState
      );

      const updateAction = checkQuantityAction(updatedQty);

      updateOrder(
        saveOrderId,
        itemId,
        updatedQty,
        itemName,
        updateAction,
        priceInKg,
        unitInKg
      );
    } else {
      console.log("cartVC Invalid saveOrderId : ", saveOrderId);

      handleQtyUpdateAction(updatedQty, itemName, itemId, price);
    }
  };

  const addToCartActionHandler = (item: CartItem) => {
    if (isValidNumber(saveOrderId)) {
      if (item.unitInKg === 0 || item.unitInKg === undefined) {
        console.log("cartVC valid addToCartActionHandler : ", saveOrderId);
        updateOrder(saveOrderId, item.id, 1, item.name, "add", 0, 0);
      } else {
        updateOrder(
          saveOrderId,
          item.id,
          0,
          item.name,
          "addByUnit",
          item.priceInKg,
          item.unitInKg
        );
      }
    } else {
      console.log("cartVC Invalid addToCartActionHandler : ", saveOrderId);

      addToCart(item);
    }
  };

  return (
    <SearchFood
      selectedSeat={seatName}
      foods={foods}
      categories={categories}
      error={error}
      loading={loading}
      onSearchFoodClick={(searchValue, action) => {
        loadFoods(searchValue, action);
      }}
      addToCart={addToCartActionHandler}
      cartItems={cart}
      handleQtyUpdateAction={updateActionHandler}
      totalAmount={totalAmount}
      saveOrder={(cartItems, seatName) => saveOrder(cartItems, seatName)}
      saveOrderResponseState={responseState}
      onCloseModel={onCloseModel}
      shouldDisplayModelFlag={setShouldDisplayModel}
      updateOrderState={updateOrderState}
      existingOrdersBySeatRes={existingBySeatNameState}
    />
  );
};

import React, { createContext, useContext, useState, ReactNode } from "react";

type SeatServiceContextType = {
  seatName: string;
  setSeatName: React.Dispatch<React.SetStateAction<string>>;
};

export const SeatContext = createContext<SeatServiceContextType | undefined>(
  undefined
);

interface SeatProviderProps {
  children: ReactNode;
}

export const SeatProvider: React.FC<SeatProviderProps> = ({ children }) => {
  const [seatName, setSeatName] = useState("");

  return (
    <SeatContext.Provider value={{ seatName, setSeatName }}>
      {children}
    </SeatContext.Provider>
  );
};

export const useSeatContext = () => {
  const context = useContext(SeatContext);
  if (!context) {
    throw new Error("useSeatContext must be used within a SeatProvider");
  }
  return context;
};

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface AutoCloseMessageModalProps {
  showInitially: boolean; // Indicates if the modal should show on mount
  message: string;
  redirectPath: string; // Path to redirect after closing the modal
}

const AutoCloseMessageModal: React.FC<AutoCloseMessageModalProps> = ({
  showInitially,
  message,
  redirectPath,
}) => {
  const [show, setShow] = useState(showInitially);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false); // Automatically close the modal
        navigate(redirectPath); // Navigate to the specified path
      }, 1000); // Closes after 2000 milliseconds (2 seconds)

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [show, redirectPath, navigate]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
    </Modal>
  );
};

export default AutoCloseMessageModal;

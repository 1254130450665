import React from 'react';
import { useCart } from '../../context/CartContext';

interface CartDropdownProps {
    setCartDropdown: (dropdown: boolean) => void;
}

const CartDropdown: React.FC<CartDropdownProps> = ({setCartDropdown}) => {
    const { cart } = useCart();

    return (
        <div style={{
            position: 'absolute',
            right: 0,
            top: '100%',
            backgroundColor: 'white',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0,0,0,.15)',
            width: '300px',
            zIndex: 1050  // Use a high value here to ensure it's on top of other content
        }}>
            <h6>Cart Items</h6>
            {cart.length > 0 ? (
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {cart.map(item => (
                        <li key={item.id} style={{ margin: '10px 0' }}>
                            {item.name} - {item.quantity} x ${item.price.toFixed(2)}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Your cart is empty.</p>
            )}
            <div className="text-center mt-2">
                <button className="btn btn-outline-secondary btn-sm" onClick={() => setCartDropdown(false)}>View Cart</button>
            </div>
        </div>        
    );
};

export default CartDropdown;

import { useCallback, useState } from "react";
import { CartItem, ResponseUpdateItemState } from "../../models/ResponseModels";
import CartItemComponent from "./CartItem";
import MessageModal from "../Utils/MessageModal";

interface CartDropdownProps {
  handleQtyUpdateAction: (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number,
    priceInKg: number,
    unitInKg: number
  ) => void;
  cartItems: CartItem[];
  totalAmount: number;
  placeOrder: () => void;
  seatname: string;
  updateOrderState: ResponseUpdateItemState;
  showMessageModel: boolean;
  handleOnCloseModelAction: () => void;
  existingOrderId: number;
}

const Cart: React.FC<CartDropdownProps> = ({
  handleQtyUpdateAction,
  cartItems,
  totalAmount,
  placeOrder,
  seatname,
  updateOrderState,
  showMessageModel,
  handleOnCloseModelAction,
  existingOrderId,
}) => {
  const [isLoading, setIsLoaing] = useState<boolean>(false);
  //const [showModel, setShowModel] = useState<boolean>(true);

  const handleQtyUpdate = (
    updatedQty: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    handleQtyUpdateAction(updatedQty, itemName, itemId, price, 0, 0);
  };

  const incrementCount = (
    count: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    console.log("Incrementing count from:", count);
    handleQtyUpdate(count + 1, itemName, itemId, price);
  };

  const decrementCount = (
    count: number,
    itemName: string,
    itemId: number,
    price: number
  ) => {
    if (count > 0) {
      console.log("Decrementing count from:", count);
      handleQtyUpdate(count - 1, itemName, itemId, price);
    }
  };

  // const handleIsLoading = useCallback(() => {
  //   setIsLoaing(updateOrderState.loading!!);
  // }, [setIsLoaing]);

  if (updateOrderState.error!!) {
    // setShowModel(true);
    return (
      <MessageModal
        show={showMessageModel}
        onClose={handleOnCloseModelAction}
        message={updateOrderState.error!!}
      />
    );
  }

  console.log("Cart updateOrderState : ", updateOrderState);

  if (updateOrderState.error!!) {
    // setShowModel(true);
    return (
      <MessageModal
        show={showMessageModel}
        onClose={handleOnCloseModelAction}
        message={updateOrderState.error!!}
      />
    );
  }

  return (
    <div className="container mt-4">
      <div className="card shadow">
        <div className="card-header d-flex justify-content-between">
          <h4 className="mb-0">{seatname}</h4>
          <h4 className="mb-0 text-muted">Order ID: {existingOrderId}</h4>
        </div>
        <div className="card-body">
          <p className="text-secondary mb-3">ग्राहकको सामानहरु:</p>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <CartItemComponent
                    key={item.id}
                    item={item}
                    onIncrement={incrementCount}
                    onDecrement={decrementCount}
                    loading={updateOrderState.loading!!}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={3}>Total</th>
                  <th>${totalAmount.toFixed(2)}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <button
            className="btn btn-outline-secondary w-100 mt-3"
            style={{
              padding: "10px",
              fontSize: "1rem",
              backgroundColor: "#6c757d",
              color: "#ffffff",
              border: "1px solid #cccccc",
            }}
            onClick={placeOrder}
            disabled={existingOrderId !== 0 || cartItems.length === 0}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;

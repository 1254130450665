import { OrderHistory } from "../../components/OrderPage/OrderHistory";
import { useOrderHistoryViewModel } from "../viewModel/OrderHistoryViewModel";

export const OrderViewViewController = () => {
  console.log("SeatViewController rendering"); // Check if this logs

  const { completeOrderRes, loadCompletedOrdersByDate, onCloseModel } =
    useOrderHistoryViewModel();

  return (
    <OrderHistory
      completeOrderRes={completeOrderRes}
      onCloseModel={onCloseModel}
      loadCompletedOrdersByDate={loadCompletedOrdersByDate}
    />
  );
};

import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ByPieceOrUnitModalProps {
  show: boolean;
  handleClose: () => void;
  handleAddByPiece: () => void;
  handleAddByUnit: () => void;
}

const ByPieceOrUnitModal: React.FC<ByPieceOrUnitModalProps> = ({
  show,
  handleClose,
  handleAddByPiece,
  handleAddByUnit,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Item </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>How would you like to add the item?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAddByPiece}>
          Add By Piece
        </Button>
        <Button variant="primary" onClick={handleAddByUnit}>
          Add By Unit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ByPieceOrUnitModal;
